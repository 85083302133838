import * as React from "react";
import { map } from "lodash";
import { Card } from "@rmwc/card";
import { ChipSet, Chip } from "@rmwc/chip"
import { Typography } from "@rmwc/typography";

export const URITags = ({tags, changeTags}) => {
  return (
    <div>
      <Card>
        <Typography use="headline6">Tags</Typography>
        <ChipSet>
          {
            map(tags, (tag, key) => {
              return (
                <Chip
                  key={key}
                  label={tag}
                  icon="close"
                  onInteraction={evt => {
                   console.log('onInteraction', evt.detail);
                   changeTags(evt.detail.chipId);
                 }}
                />
              )
            })
          }
        </ChipSet>
      </Card>
    </div>
  )
};
