export const ReverseObjArray = (obj) => {
  // console.log("reverse", obj)
  // let newArray: any[] = []
  let newObject: object = {}

  Object.keys(obj)
    .sort()
    .reverse()
    .forEach((key) => {
      // console.log('key', key)
      newObject[key] = obj[key]
      // newArray.push(obj[key])
    })

  // console.log(newArray)
  // console.log('newObject', newObject)
  return newObject
}
