import * as React from "react";
import Moment from "react-moment";

import { URIEdit } from "../URIEdit";
import { URISaveData } from "src/util/SavableURI";

import { Button } from "@rmwc/button";


export const URISuffix = (props: { pathData: URISaveData, parent?: string, fbPath: any; uid?: string }) => {
  const hash = props.pathData.hash ? `${props.pathData.hash}` : '';
  const title = props.pathData.title ? props.pathData.title : hash;
  const pathArray =  props.fbPath.split("/");
  const fbUid = props.fbPath && pathArray[pathArray.length -1];

  const [ open, setOpen ] = React.useState(false);

  const toggleOpen = e => {
    setOpen(!open)
  }

  return (
    <span className="uri-suffix">

      <a
        href={`https://${props.parent}${hash}`}
        target="_blank"
        rel="noopener noreferrer"
        className="uri-suffix-link"
      >

        { props.pathData.favIconUrl ?
            <img src={props.pathData.favIconUrl} alt={title} /> :
            null }

        <h3 className="uri-suffix-title">{title} ({hash})</h3>

      </a>


      { props.pathData.dateAdded ? <small>Added <Moment fromNow>{props.pathData.dateAdded}</Moment></small> : null }

      <Button raised onClick={toggleOpen} className="uri-edit-button">Edit</Button>

      { open ? <URIEdit uri={props.pathData.uri} fbPath={props.fbPath} title={title} fbUid={fbUid} /> : null }

      { props.pathData.dateUpdated ? <small>Updated <Moment fromNow>{props.pathData.dateUpdated}</Moment></small> : null }

    </span>
  )
}
