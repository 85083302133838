import * as React from "react";
import * as routes from "../../../constants/routes";
import { auth } from "../../../firebase";
import { Button } from "@rmwc/button";

interface InterfaceProps {
  error?: any;
  history?: any;
}

interface InterfaceState {
  error: any;
}

export class SignInGoogle extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  private static INITIAL_STATE = {
    error: null
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: InterfaceProps) {
    super(props);

    this.state = { ...SignInGoogle.INITIAL_STATE };
  }

  public googleLogin = (event: any) => {
    const { history } = this.props;

    auth
      .doSignInWithGoogle()
      .then(() => {
        this.setState(() => ({ ...SignInGoogle.INITIAL_STATE }));
        history.push(routes.URIS);
      })
      .catch(error => {
        this.setState(SignInGoogle.propKey("error", error));
      });

    event.preventDefault();
  }

  public render() {
    const { error } = this.state;

    return (
      <div>
        <Button onClick={this.googleLogin}>Google Signin</Button>
        {error && <p>{error.message}</p>}
      </div>
    );
  }
}
