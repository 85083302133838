import * as firebase from "firebase/app";
import { URIObject } from "../constants/URIs";
import { reduce, includes } from 'lodash';
import { Youtube } from "../constants/path-filters";

export interface URIOverrideData {
  title: string;
  favIconUrl?: string | null;
}

export interface URISaveData {
  title: string;
  text?: string;
  params?: string | null;
  favIconUrl?: string | null;
  hash?: string | null;
  protocol?: string;
  suffix?: string | null;
  dateUpdated?: string;
  dateAdded?: string;
  tags?: string[];
  uid?: string;
  uri: string;
}

export interface URISaveObject {
  savablePath: string | null;
  saveData: URISaveData;
  savableTLD: string;
  savableUID: string | null;
  parameters: Params;
  savableHash?: string | null;
  savableTags: string[];
}

export interface Params {
  [prop: string]: string;
}

export interface SavePath {
  tld: string,
  path: string | null,
  lastPath?: string
}


export class SavableURI {
  public readonly uriObject: URIObject;
  // fb valid path check
  // private pathRegex = new RegExp(/[.#$[]](\/[0-9a-zA-Z].*\?|$)/g);
  private pathRegex = new RegExp(/^[0-9a-zA-Z/-]+[^.#$?[\]]$/g);


  constructor (uriObject: URIObject) {
    this.uriObject = uriObject;
  }

  public getURISaveObject (data?: URIOverrideData): URISaveObject {
    const savePaths = this.getSavePaths();
    const saveData = this.getSaveData(data, savePaths.lastPath);
    const parameters = this.breakDownParams(saveData.params);
    const savableUID = this.getSavableUID(savePaths, parameters)

    // console.log('getURISaveObject savePaths', savePaths);

    return {
      savablePath: savePaths.path,
      savableTLD: savePaths.tld,
      savableUID,
      parameters,
      saveData,
      savableHash: saveData.hash,
      savableTags: this.getTagsFromTitle(saveData.title)
    }
  }

  public getTagsFromTitle (title: string): string[] {
    // TODO: Also get tags from tld, hash and query
    const tags: string[] = [];
    const split = title.split(' ')
    split.forEach((word: string) => {
      if (new RegExp(/^[0-9a-zA-Z/-]+[^.#$?[\]]$/g).test(word) && !this.isWordBlacklisted(word)) {
        tags.push(word)
      }
    })
    return tags
  }

  public isWordBlacklisted (word: string) {
    const blacklistedWords = [
      'the',
      'a',
      'from',
      'to',
      'with',
      'is',
      'of',
      'for',
      'was',
      'will',
      'be',
      'by'
    ];
    return includes(blacklistedWords, word.toLowerCase());
  }


  public breakDownParams (params?: string | null): Params {
    // console.log('params', params)
    if (params && params.split('?')) {
      const stripQ = params
        .split('?')[1];

      const paramsSplit = stripQ && stripQ
        .split('&');

      return reduce(paramsSplit, (memo, param) => {
        const paramArray = param
          .split('=');

        const prop = paramArray[0];
        const val = paramArray[1];

        memo[prop] = val;

        return memo;
      }, {})
    } else {
      return {}
    }
  }

  public getSavableUID (savePath: SavePath, parameters?: Params) {
    const splitTldPath = savePath.tld
      .split('/')[1];

    if (splitTldPath === 'youtube' && parameters) {
      return parameters &&
        parameters[Youtube.uid];
    }
    return null;
  }

  public getSavePaths (): SavePath {
    // Reverse path
    const tldPath = this.getReverseTldPath();
    const path = this.validatePath();

    return {
      tld: tldPath,
      path: path ?
        path :
        null,
      lastPath: [ ...this.uriObject.paths ]
        .pop()
    }
  }

  public validatePath () {
    let validPath = '';

    // Prepare path
    const path = this.uriObject.paths
      .join('/');

    let finalPath = `/${path}`;

    // Check path is valid
    const checkValid = this.pathRegex.test(finalPath);

    if (checkValid) {
      return finalPath

    } else {
      try {
        // lastPath = [ ...this.uriObject.paths ].pop();
        const pathsArray = [ ...this.uriObject.paths ];

        pathsArray.length = pathsArray
          .length - 1;

        validPath = pathsArray.join('/');

        // Check again
        const checkValidAgain = this.pathRegex
          .test(validPath);

        if (!checkValidAgain) {
          throw Error(`Not valid: ${validPath}`);
        }

        finalPath = `/${validPath}`;

      } catch {
        if (validPath === '') {
          finalPath = '';
        }
      }

      return finalPath
    }
  }

  public getReverseTldPath (): string {
    const tldSplit = this.uriObject.tld
      .split(':');

    const tldNoPort = tldSplit[0];
    const tldPort = tldSplit[1];

    const tldPathJoined = tldNoPort
      .split('.')
      .reverse()
      .join('/');

    return tldPort ?
      `${tldPathJoined}:${tldPort}` :
      tldPathJoined;
  }

  public getSaveData (data?: URIOverrideData, lastPath?: string): URISaveData {
    // TODO: Check if lastPath is path of suffix like page.html
    const { params, path } = this.uriObject;

    const title = data && data.title !== '' ?
      data.title :
      this.getTitleFromPath(path)

    const favIconUrl = data && data.favIconUrl ?
      data.favIconUrl :
      null;

    return {
      title,
      favIconUrl,
      params: params ?
        params :
        null,
      hash: this.uriObject.hash ?
        this.uriObject.hash :
        null,
      protocol: this.uriObject.protocol,
      suffix: lastPath ?
        lastPath :
        null,
      dateUpdated: this.addDate(),
      uri: this.uriObject.uri
    }
  }

  public addDate (): string {
    return firebase.database.ServerValue.TIMESTAMP as string
  }

  public getTitleFromPath (str?: string | null): string {
    // TODO: Remove encoded spaces etc. (%20)
    if (str) {
       // Path should always start with /, so strip it.
      let title = str
        .slice(1)

      title = title
        .split('-').join(' ');
      title = title
        .split('/').join(' ');

      title = title
        .replace(
          /(^\.+|\.html+$)/mg,
          ''
        );

      const titleCapitalized = title
        .charAt(0)
        .toUpperCase()
        + title.slice(1)

      return titleCapitalized
    } else {
      return 'No title'
    }
  }
}
