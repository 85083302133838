import { Users, User } from "src/constants/users";

// Actions
export const USERS_SET = 'USERS_SET';

interface SetUsers {
  type: typeof USERS_SET;
  payload: Users;
}

export function setUsers(users: Users): UserActionTypes {
  return {
    type: USERS_SET,
    payload: users
  }
}

export type UserActionTypes =
  SetUsers

// Reducers
export interface UsersState {
  users: {
    [key: string]: User
  }
}

const INITIAL_STATE = {
  users: {}
};

const applySetUsers = (state: UsersState, action: any) => ({
  ...state,
  users: action.users
});

export function userReducer(state = INITIAL_STATE, action: UserActionTypes): UsersState {
  switch (action.type) {
    case USERS_SET: {
      return applySetUsers(state, action);
    }
    default:
      return state;
  }
}
