import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import classNames from "classnames";
import { compose } from "recompose";
import { connect } from "react-redux";

import * as routes from "../../constants/routes";
import { firebase } from "../../firebase";

import { Account } from "../Auth/Account";
import { PasswordForget } from "../Auth/PasswordForget";
import { withAuthentication } from "../Auth/Session/withAuthentication";
import { SignIn } from "../Auth/SignIn";
import { SignUp } from "../Auth/SignUp";

import { Home } from "../Home";
import { Landing } from "../Landing";
import { Navigation } from "../Navigation";
import { Users } from "../Users";
import { Sessions } from "../Sessions";
import { URIsPage } from "../URI";
import { TagsPage } from "../Tags";
import { Groups } from "../Groups";

import { Fab } from '@rmwc/fab';

import "./App.scss";
import "../../global.scss"
import { Card } from "@rmwc/card";

interface AppProps {
  toggleBottomDrawer: () => void;
  bottomDrawerExpanded: boolean;
}

class AppComponent extends React.Component<AppProps, { authUser: any }> {

  constructor(props: any) {
    super(props);

    this.state = {
      authUser: null
    };
  }

  public componentDidMount() {
    firebase.auth().onAuthStateChanged(authUser => {
      authUser ?
        this.setState({ authUser }) :
        this.setState({ authUser: null });
    });
  }

  public render() {
    const routeClasses = classNames({
      'app-routes': true,
      'app-routes__without-tabs':  !this.state.authUser,
      'app-routes__with-tabs':  this.state.authUser
    })
    const { toggleBottomDrawer, bottomDrawerExpanded } = this.props;

    const bottomDrawerClasses = classNames({
      'bottom-drawer': true,
      'bottom-drawer__expanded': bottomDrawerExpanded,
    })


    return (
      <BrowserRouter>
        <div className='app'>

          <Navigation />

          <div className={routeClasses}>
            <Switch>
              <Route exact={true} path={routes.LANDING} component={Landing} />
              <Route exact={true} path={routes.SIGN_UP} component={SignUp} />
              <Route exact={true} path={routes.SIGN_IN} component={SignIn} />
              <Route exact={true} path={routes.PASSWORD_FORGET} component={PasswordForget} />
              <Route exact={true} path={routes.HOME} component={Home} />
              <Route exact={true} path={routes.URIS} component={URIsPage} />
              <Route exact={true} path={routes.TAGS} component={TagsPage} />
              <Route exact={true} path={routes.SESSIONS} component={Sessions} />
              <Route exact={true} path={routes.GROUPS} component={Groups} />
              <Route exact={true} path={routes.ACCOUNT} component={Account} />
              <Route exact={true} path={routes.USERS} component={Users} />
              <Route component={SignIn} />
            </Switch>
          </div>

          <Fab icon="add" className="global-fab" onClick={toggleBottomDrawer} />

          <div className={bottomDrawerClasses}>
            <Card>
              Drawer
            </Card>
          </div>

        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state: any) => ({
  bottomDrawerExpanded: state.appState.bottomDrawerExpanded
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleBottomDrawer: () => dispatch({ type: "TOGGLE_BOTTOM_DRAWER" })
});


export const App = withAuthentication(compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppComponent as any))
