import { URIs } from "src/constants/URIs";

// Actions
export const URIS_UPDATED = 'URIS_UPDATED';
export const URIS_UPDATED_ERROR = 'URIS_UPDATED_ERROR';
export const URIS_WATCH_UPDATE = 'URIS_WATCH_UPDATE';

interface UpdateURIS {
  type: typeof URIS_UPDATED
  payload: URIs
}

interface UpdateURISError {
  type: typeof URIS_UPDATED_ERROR
  payload: URIs
}

export interface WatchUpdateURIS {
  type: typeof URIS_WATCH_UPDATE
}

export type URIActionTypes =
  UpdateURIS |
  UpdateURISError |
  WatchUpdateURIS

export function updateURIS(uris: URIs): UpdateURIS {
  return {
    type: URIS_UPDATED,
    payload: uris
  }
}

export function updateURISError(err): UpdateURISError {
  return {
    type: URIS_UPDATED_ERROR,
    payload: err
  }
}

export function watchUpdateURIS(): WatchUpdateURIS {
  return {
    type: URIS_WATCH_UPDATE,
  }
}

export const watchUpdateUrisAction = () => ({ type: URIS_WATCH_UPDATE })
export const watchUpdateUrisActionError = (err: any) => ({ type: URIS_UPDATED_ERROR, payload: err })

export const onUpdateUrisAction = (uris: any) => ({ type: URIS_UPDATED, payload: uris })


// Reducers
export interface URIState {
  uris: URIs;
  loading: boolean;
}

const INITIAL_STATE = {
  uris: [],
  loading: true
};

export function uriReducer(state: URIState = INITIAL_STATE, action: URIActionTypes): URIState {
  switch (action.type) {
    case URIS_WATCH_UPDATE:
      console.log(action)
      return {
        ...state,
        loading: true
      };
    case URIS_UPDATED:
      console.log(action)
      return {
        ...state,
        loading: false,
        uris: action.payload
      };
    case URIS_UPDATED_ERROR:
      console.log(action)
      return {
        ...state,
        loading: false,
        uris: action.payload
      };
    default:
      return state;
  }
}
