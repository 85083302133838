import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const config = {
    apiKey: "AIzaSyAYesk4_5iPCzvMmCthdddW3HcVyz_-KeM",
    appId: "1:128561426477:web:51c4b2795d63aeda",
    authDomain: "stuff-c1314.firebaseapp.com",
    databaseURL: "https://stuff-c1314.firebaseio.com",
    messagingSenderId: "128561426477",
    projectId: "stuff-c1314",
    storageBucket: "stuff-c1314.appspot.com"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const auth = firebase.auth;
export const db = firebase.database();
