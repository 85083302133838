import * as React from "react";
import { URIs } from "src/constants/URIs";
import { map, reduce } from 'lodash';
import { URIPaths } from "./URIPaths";

import { Card } from "@rmwc/card";
import { Typography } from "@rmwc/typography";
import { Icon } from "@rmwc/icon";
import { Ripple } from "@rmwc/ripple";
import { Elevation } from "@rmwc/elevation";

interface URIDomainsProps {
  uris: URIs;
  parent?: string;
  fbPath?: any;
  open?: boolean;
  pathsOpen?: boolean;
}

interface URIDomainsState {
  childrenOpen: {
    [key: string]: boolean | any;
  };
  elevation: number;
}

export class URIDomains extends React.Component<URIDomainsProps, URIDomainsState> {

  public static defaultProps = {
    open: false,
    pathsOpen: false
  }

  public static getDerivedStateFromProps (props, state) {
    return {
      childrenOpen: {
        // Set initial keys to 'open'
        ...reduce(props.uris, (memo, urisItem, key: string) => {
          memo[key] = props.open;
          return memo;
        }, {}),
        // State later takes precedence
        ...state.childrenOpen
      }
    }
  }

  constructor(props: URIDomainsProps) {
    super(props);
    this.state = {
      childrenOpen: {},
      elevation: 3
    };
  }

  public toggleChild (e: React.MouseEvent<any, MouseEvent>, key: string) {
    e.stopPropagation();

    this.setState({
      childrenOpen: {
        ...this.state.childrenOpen,
        [key]: !this.state.childrenOpen[key]
      }
    })
  }

  public isChildOpen (key: string) {
    return this.state.childrenOpen[key] ? this.state.childrenOpen[key] : false;
  }

  public render() {
    const { uris, parent, fbPath, pathsOpen, open } = this.props;
    const { elevation } = this.state;
    const setElevation = (e) => this.setState({
      elevation: e
    });

    return (
      <Elevation
        z={elevation}
        transition
        onMouseOver={() => setElevation(16)}
        onMouseOut={() => setElevation(3)}
        wrap
      >
        <Card>
          {
            uris ? map(uris, (urisItem, key) => {
              if (typeof urisItem === 'object' && key !== '_paths') {

                // Turn into domain name
                const parentItem = parent ?
                  `${key}.${parent}` :
                  key;

                const fbPathItem = parent ?
                  `${fbPath}/${key}` :
                  key;

                return (
                  <div key={key} className="uri-domains" onClick={(e) => this.toggleChild(e, key)}>

                    { parent ?
                      <a href={`https://${parentItem}`} target="_blank" rel="noopener noreferrer"><Typography use="headline3">{key}</Typography></a>
                      :
                      <Typography use="headline3">{key}</Typography>
                    }

                    <Ripple accent>
                      <Icon
                        icon={{ icon: this.isChildOpen(key) ? 'chevron_left' : 'double_arrow', size: 'xlarge' }}
                        onClick={(e) => this.toggleChild(e, key) }
                       />
                     </Ripple>

                    {
                      this.isChildOpen(key) ?
                        <URIDomains
                          uris={urisItem}
                          parent={parentItem}
                          fbPath={fbPathItem}
                          open={open}
                          pathsOpen={pathsOpen}
                        />
                        : null
                    }

                  </div>
                )
              } else if (key === '_paths') {
                  const fbPathItem = `${fbPath}/_paths`;
                  return <URIPaths key={key+urisItem} paths={urisItem} parent={parent} fbPath={fbPathItem} open={pathsOpen}/>
              } else {
                console.log("ERROR:", urisItem);
                return <div key={key}>ERROR</div>
              }
            })
            : <div>No uris added yet</div>
          }
        </Card>
      </Elevation>
    );
  }
};
