import { URIObject } from "src/constants/URIs";

export interface URIBrokedown {
  URIObject: URIObject;
  title: string;
}

export class BreakdownURI {
  public uri: string;
  public url!: URL;
  public data!: object;

  constructor (uri: string) {
    this.uri = uri;
  }

  public getURIObject (): URIObject {
    try {
      this.url = new URL(this.uri);

      if (this.uri && (this.url.protocol === 'https:' || this.url.protocol === 'http:')) {

        return {
          uri: this.uri,
          tld: this.removeWWW(this.url.host),
          path: this.getPath(this.url.pathname),
          params: this.getParams(this.url.search),
          paths: checkForKnownPathpatterns(this.url.pathname),
          hash: this.url.hash ? this.url.hash : null,
          protocol: this.url.protocol
        }
      } else {
        // TODO: Fix handle unkown protocols and manually extract TLDs
        throw Error('Unknow protocol')
      }
    }
    catch {
      return {
        uri: '',
        tld: '',
        path: null,
        params: null,
        paths: [ ],
        hash: null,
        protocol: ''
      }
    }

  }

  private removeWWW (host: string) {
    return host.replace(/^(?:www\.)?/i, '')
  }

  private getPath (path: string) {
    return path;
  }

  private getParams (search: string) {
    return search ? search : null;
  }

}


const checkForKnownPathpatterns = (path: string) => {
  // TODO: Make sure starts with `/`
  const array = path.split('/')
  const cleanArray = array.filter((item) => item !== '');
  return cleanArray;
}
