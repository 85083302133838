import * as React from "react";

import { Typography } from "@rmwc/typography";
import { Grid, GridCell } from "@rmwc/grid";

export const Landing = () => {
  return (
    <div>
    <Grid>
      <GridCell span={12}>
        <Typography use="headline1">There be stuff</Typography>
      </GridCell>
    </Grid>
    </div>
  );
};
