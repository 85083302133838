
// Actions
export const TOGGLE_BOTTOM_DRAWER = 'TOGGLE_BOTTOM_DRAWER'

interface ToggleBottomDrawer {
  type: typeof TOGGLE_BOTTOM_DRAWER;
}

export function toggleBottomDrawer(): AppActionTypes {
  return {
    type: TOGGLE_BOTTOM_DRAWER,
  }
}

export type AppActionTypes =
  ToggleBottomDrawer

// Reducers
export interface AppState {
  bottomDrawerExpanded: boolean;
}

const INITIAL_STATE = {
  bottomDrawerExpanded: false
};

export function appReducer(state = INITIAL_STATE, action: AppActionTypes) {
  switch (action.type) {
    case TOGGLE_BOTTOM_DRAWER: {
      return {
        ...state,
        bottomDrawerExpanded: !state.bottomDrawerExpanded
      }
    }
    default:
      return state;
  }
}
