import * as firebase from "firebase/app";
import { privateTagsRef } from "./database";
import { map, reduce, xor, differenceWith, isEqual, includes } from "lodash";

export interface Tag {
  title: string;
  uids: {
    [uid: string]: {
      dateAdded: string;
    }
  }
}

export function saveTags (tags: string[], uid: string) {

  map(tags, (tagName: string) => {
    const tagRef = privateTagsRef()
       .child(tagName)

    tagRef.child('uids').once('value', (data) => {
      if (data.hasChild(uid)) {
        tagRef.child('uids').child(uid)
          .update({
            dateUpdated: firebase.database.ServerValue.TIMESTAMP
          })
      } else {
        tagRef.child('uids').child(uid)
          .update({
            dateCreated: firebase.database.ServerValue.TIMESTAMP
          })
      }
    })

    tagRef.child('dateUpdated').set(firebase.database.ServerValue.TIMESTAMP)

  })

}

export function removeTags (removedTags: string[], uid: string) {

  console.log('removeTags', removedTags)

  map(removedTags, (tagName: string) => {
    const tagRef = privateTagsRef()
       .child(tagName)

    tagRef.child('uids').once('value', (data) => {
      if (data.hasChild(uid)) {
        tagRef.child('uids').child(uid)
          .remove()
      } else {
        console.error("Tag to be removed doesn't exist:", tagName, uid)
      }
    })
  })

}

export function updateTags (oldTags: string[], newTags: string[], uid: string) {

  console.log('oldTags', oldTags)
  console.log('newTags', newTags)

  const diffTags = xor(newTags, oldTags);
  const addTags = differenceWith(newTags, oldTags, isEqual);

  const removedTags = reduce(diffTags, (memo, diffTag, key) => {
    if (includes(addTags, diffTag)){
      console.log('found', diffTag)
    } else  {
      console.log('not find', diffTag)
      memo.push(diffTag as never)
    }
    return memo;
  }, [])

  console.log('removedTags', removedTags);
  console.log('addTags', addTags);

  // Remove old Tags
  map(removedTags, (tagName: string) => {
    const tagRef = privateTagsRef()
       .child(tagName)

    tagRef.child('uids').once('value', (data) => {
      if (data.hasChild(uid)) {
        tagRef.child('uids').child(uid)
          .remove()
      } else {
        console.error("Tag to be removed doesn't exist:", tagName, uid)
      }
    })
  })

  // Add new Tags
  saveTags(addTags,uid)

}
