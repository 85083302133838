import { URISaveData } from "src/util/SavableURI";

type UIDS = string[];


// Actions
export const UIDS_FETCH = 'UIDS_FETCH';
export const UID_FETCH_DONE = 'UID_FETCH_DONE';
export const UID_FETCH = 'UID_FETCH';



// Action interfaces
export interface FetchUIDSAction {
  type: typeof UIDS_FETCH
  payload: UIDS
}

export interface FetchUID {
  type: typeof UID_FETCH
  payload: string
}

interface FetchUIDActionDone {
  type: typeof UID_FETCH_DONE
  payload: URISaveData;
  meta: string;
}

export type UIDActionTypes =
  FetchUIDSAction |
  FetchUID |
  FetchUIDActionDone




// Action creators
export function fetchUIDs(uids: UIDS): FetchUIDSAction {
  return {
    type: UIDS_FETCH,
    payload: uids
  }
}

export function fetchUID(uid: string): FetchUID {
  return {
    type: UID_FETCH,
    payload: uid
  }
}

export function fetchUIDDone(payload: URISaveData, uid: string): FetchUIDActionDone {
  return {
    type: UID_FETCH_DONE,
    payload,
    meta: uid
  }
}




// Reducers
export interface UIDState {
  uids: {
    [uid: string]: URISaveData
  };
  loading: boolean;
  error: any;
}

const INITIAL_STATE = {
  uids: {},
  loading: false,
  error: null
};




export function uidReducer(state: UIDState = INITIAL_STATE, action: UIDActionTypes): UIDState {
  switch (action.type) {
    case UIDS_FETCH: {
      return {
        ...state,
        loading: true
      }
    }
    case UID_FETCH_DONE: {
      return {
        ...state,
        uids: {
          ...state.uids,
          [action.meta]: action.payload,
        },
        loading: false
      }
    }
    default:
      return state;
  }
}
