import { SessionsData, BrowserSession } from "src/constants/sessions";

// Actions
export const PRIVATE_SESSIONS_UPDATE = 'PRIVATE_SESSIONS_UPDATE';

interface PrivateSessionsUpdate {
  type: typeof PRIVATE_SESSIONS_UPDATE
  payload: SessionsData
}

export function privateSessionsUpdate(sessionsData: SessionsData): SessionsActionTypes {
  return {
    type: PRIVATE_SESSIONS_UPDATE,
    payload: sessionsData
  }
}

export type SessionsActionTypes =
  PrivateSessionsUpdate

// Reducers
export interface SessionsState {
  browserSession: {
    [browserId: number]: BrowserSession;
  }
}

const INITIAL_STATE = {
  browserSession: {}
};

const applySessionsData = (state: SessionsState, action: any) => ({
  ...state,
  browserSession: action.data
});

export function sessionsReducer(state = INITIAL_STATE, action: SessionsActionTypes): SessionsState {
  switch (action.type) {
    case PRIVATE_SESSIONS_UPDATE: {
      return applySessionsData(state, action);
    }
    default:
      return state;
  }
}
