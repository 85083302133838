import * as React from "react";
import Moment from "react-moment";

import { URIBreakdown } from "../URIBreakdown";
import { URITags } from "../URITags";

import { URIObject } from "src/constants/URIs";
import { URIInput } from "./URIInput";
import { privateURIRef } from "src/util/database";
import { saveURI, getFirebasePathFromSavableObject, getSavableFromURI, getUriObjectFromURI, checkIfURLNotAllowed, deleteURI } from "src/util/uri";
import { Card } from '@rmwc/card';
import { Button } from '@rmwc/button';
import { URISaveData } from "src/util/SavableURI";

import './URIAdd.scss';

interface InterfaceState {
  uri: string;
  title: string;
  uriObject: URIObject;
  loading: boolean;
  exists: boolean;
  tags: any;
  dateUpdated?: string;
  invalidURI: boolean;
}

export class URIAdd extends React.Component<{}, InterfaceState> {

  private static INITIAL_STATE = {
    uri: "https://tweakers.net/pricewatch/1169465/seagate-skyhawk-surveillance-6tb-(st6000vx001).html",
    title: '',
    uriObject: {
      uri: '',
      tld: '',
      path: '',
      params: '',
      paths: [ ],
      hash: '',
      protocol: ''
    },
    loading: true,
    exists: false,
    tags: {},
    dateUpdated: '',
    invalidURI: false
  };

  constructor (props) {
    super(props);
    this.state = { ...URIAdd.INITIAL_STATE };
  }

  public componentDidMount() {

    this.bindPath(this.state.uri);

    this.setUriObject();
  }


  public getFbPathOfURI (uri: string): string {
    const savableObject = getSavableFromURI(uri);
    return getFirebasePathFromSavableObject(savableObject);
  }

  public bindPath (uri) {

    if (uri) {
      const fbPath = this.getFbPathOfURI(uri);

      privateURIRef()
        .child(fbPath)
          .on('value', (snap) => {
            const uriData = snap.val() as URISaveData;

            if (uriData) {
              // console.log('bindPath exists', uriData.title);

              this.setState({
                title: uriData.title,
                loading: false,
                exists: true,
                dateUpdated: uriData.dateUpdated,
                tags: uriData.tags,
                invalidURI: false
              })

            } else {
              const savableObject = getSavableFromURI(uri);
              // console.log('bindPath new', savableObject);

              this.setState({
                title: savableObject.saveData.title,
                loading: false,
                exists: false,
                invalidURI: false
              })

            }
          })
    } else {
      console.error("bindPath no valid uri", uri)
    }
  }

  public unbindPath(fbPath: string) {
    console.log('unbindPath', fbPath)

    privateURIRef()
      .child(fbPath)
        .off('value')
  }

  public inputEnter = () => {
    this.clickSave();
  }

  public inputChangeURI = (uri: string) => {
    const fbPath = this.getFbPathOfURI(this.state.uri);
    const uriObject = getUriObjectFromURI(uri);

    if (uriObject.tld && uriObject.uri && fbPath) {

      this.unbindPath(fbPath)
      const uriSaveObj = getSavableFromURI(uri)

      this.setState({
        uri,
        uriObject,
        title: uriSaveObj.saveData.title,
        tags: uriSaveObj.savableTags,
        invalidURI: false
      });

      this.bindPath(uriObject.uri);

    } else {

      this.setState({
        uri,
        title: 'Enter URI',
        invalidURI: true
      })

    }
  }

  public setUriObject () {
    const uriObject =  getUriObjectFromURI(this.state.uri);
    const uriSaveObj = getSavableFromURI(this.state.uri)

    if (uriObject.tld && uriObject.uri) {

      this.setState({
        uriObject,
        tags: uriSaveObj.savableTags,
        invalidURI: false
      });

    } else {

      this.setState({
        invalidURI: true
      })

    }
  }

  public inputChangeTitle = (title: string) => {
    const uriSaveObj = getSavableFromURI(this.state.uri, {
      title
    })

    if (this.state.exists) {

      this.setState({
        title
      });

    } else {

      this.setState({
        title,
        tags: uriSaveObj.savableTags
      });

    }
  }

  public inputChangeTags (tagIndex) {
    const newTags = [ ...this.state.tags ];
    newTags.splice(tagIndex, 1);

    this.setState({
      tags: newTags
    })
  }

  public render() {
    const { loading, exists, uriObject, dateUpdated, tags } = this.state;

    const isAllowed = checkIfURLNotAllowed(uriObject.uri);

    return (
      <div>

        <div className="uri-add-container">

          <Card className="uri-add-header">

            <URIInput
              uri={this.state.uri}
              title={this.state.title}
              loading={this.state.loading}
              onEnter={this.inputEnter}
              onChangeURI={this.inputChangeURI}
              onChangeTitle={this.inputChangeTitle}
            />

          </Card>

          { !this.state.invalidURI ? <URIBreakdown {...this.state.uriObject} /> : null }

          { !this.state.invalidURI ? <URITags tags={tags} changeTags={(t) => this.inputChangeTags(t)} /> : null }

        </div>

        <Card>

          <Button
            raised={!exists}
            disabled={isAllowed || loading || !uriObject.uri || this.state.invalidURI}
            onClick={this.clickSave}
          >
            {
              loading ?
                '...' :
                exists ?
                  'Update' :
                  'Save'
            }
          </Button>

          { exists ?
            <Button
              danger
              disabled={isAllowed || loading || !uriObject.uri || this.state.invalidURI}
              onClick={this.clickDelete}
            >
              Delete
            </Button>
            : null
          }

          {
            exists && dateUpdated ? <small>Updated <Moment fromNow>{dateUpdated}</Moment></small> : null
          }

        </Card>

      </div>
    );
  }

  private clickSave = () => {
    saveURI(this.state.uriObject, this.state.title, this.state.tags)

    // Rebind when deleting and saving again
    const fbPath = this.getFbPathOfURI(this.state.uri);
    this.unbindPath(fbPath)
    this.bindPath(this.state.uri)
  }

  private clickDelete = () => {
    const uriSaveObj = getSavableFromURI(this.state.uri)
    deleteURI(getFirebasePathFromSavableObject(uriSaveObj));

    this.setUriObject();
  }

}
