import * as React from "react";
import { map } from 'lodash';
import { TabComponent } from "./Tab";
import { TabCollection } from "src/constants/sessions";
import { removeBrowserTabHistory } from "src/util/uri";
import { Button } from "@rmwc/button";

interface Props {
  tabs: TabCollection;
  selectedTab: number;
  windowId: string;
}

export class HistoryComponent extends React.Component<Props> {

  public deleteHistory () {
    removeBrowserTabHistory(this.props.windowId)
  }

  public render () {
    const { tabs, selectedTab } = this.props;

    return (
      <div className="history-container">

            {
              map(tabs, (tabItem, tabId: string) => {
                return (
                  <TabComponent
                    key={tabId}
                    tab={tabItem}
                    tabId={tabId}
                    selected={selectedTab === parseInt(tabId, 10)}
                  />
                )
              })
            }

           {
             tabs ?
                <Button onClick={() => this.deleteHistory()}>Delete History</Button> :
                null
            }

      </div>
    );
  }
};
