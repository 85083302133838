import * as React from "react";
import { URISaveData } from "src/util/SavableURI";
import { ListItem, ListItemGraphic, ListItemMeta } from "@rmwc/list";
import { getSavableFromURI, getFirebasePathFromSavableObject, checkIfYoutube } from "src/util/uri";
import { privateTabsRef } from "src/util/database";

export const TabItem = (props: { tab: URISaveData, tabId: string, groupId: string}) => {

  function deleteItem (tabId, groupId) {
    // console.log('tabId', tabId)
    const saveObject = getSavableFromURI(props.tab.uri)
    const fbpath = getFirebasePathFromSavableObject(saveObject)

    privateTabsRef().child('uri').child(fbpath).remove();
    privateTabsRef().child('uid').child(tabId).remove();
    privateTabsRef().child('groups').child(groupId).child('tabs').child(tabId).remove();
  }

  const youtubeUID = checkIfYoutube(props.tab.uri);

  return (
    <div>
      {
        youtubeUID ?
          <a
            href={props.tab.uri}
            target='_blank'
            rel="noopener noreferrer"
          >
           <img
             src={`https://img.youtube.com/vi/${youtubeUID}/0.jpg`}
             alt={props.tab.title}
             className="tab-image"
            />
          </a>
          : null
      }
      <ListItem>
        <ListItemGraphic icon="open_in_new" />
          <a
            href={props.tab.uri}
            target='_blank'
            rel="noopener noreferrer"
          >
            { youtubeUID ? 'Open Video' : 'Open Link' }
          </a>
          <ListItemMeta icon="close" onClick={() => deleteItem(props.tabId, props.groupId)}/>
      </ListItem>
    </div>
  );
};


export {}
