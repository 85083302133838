import { combineReducers } from "redux";

import { appReducer } from "./app";
import { sessionReducer } from "./session";
import { userReducer } from "./user";
import { uriReducer } from "./uris";
import { sessionsReducer } from "./sessions";
import { uidReducer } from "./uids";

export const rootReducer = combineReducers({
  appState: appReducer,
  sessionState: sessionReducer,
  userState: userReducer,
  uriState: uriReducer,
  uidState: uidReducer,
  sessionsState: sessionsReducer
});

export type RootState = ReturnType<typeof rootReducer>
