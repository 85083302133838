import * as React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withAuthorization } from "../Auth/Session/withAuthorization";
import { BrowserSession } from "src/constants/sessions";
import { RootState } from "src/reducers";
import './Groups.scss';
import { privateTabsRef } from "src/util/database";
import { URIs } from "src/constants/URIs";

import { LinearProgress  } from "@rmwc/linear-progress";
import { Typography } from "@rmwc/typography";
import { TabGroups } from "./TabGroups/TabGroups";
import { URISaveData } from "src/util/SavableURI";

interface GroupsProps {
  browserSession: BrowserSession;
  onBrowserRef: typeof onBrowserRefUpdate;
}

export interface TabGroupI {
  name: string;
  dateCreated: string;
  tabs: {
    [tabId: string]: string;
  }
}

export interface TabsData {
  groups: {
    [groupId: string] : TabGroupI;
  };
  uri: URIs;
  uid: {
    [uid: string]: URISaveData
  };
}

interface GroupsState {
  loadingTabs: boolean;
  tabs: TabsData;
}

class GroupsComponent extends React.Component<GroupsProps, GroupsState> {
  constructor (props, state) {
    super(props, state);
    this.state = {
      loadingTabs: true,
      tabs: {
        groups: {},
        uid: {},
        uri: {}
      }
    }
  }

  public componentDidMount() {

    privateTabsRef()
      .on("value", (snapshot) => {
        this.setState({
          tabs: snapshot.val(),
          loadingTabs: false
        })
      })
  }

  public componentWillUnmount () {
    privateTabsRef().off("value");
  }


  public render() {
    // const { browserSession } = this.props;
    const { loadingTabs, tabs } = this.state;

    return (
      <div className="groups-container">

        <Typography use="headline5"> Groups </Typography>

        <div>
          {
            loadingTabs ?
              null
              : <TabGroups tabs={tabs} />
          }
        </div>

        { loadingTabs ?
          <LinearProgress />
          : null
        }
      </div>
    );
  }
}

const onBrowserRefUpdate = (data: any) => ({ type: "PRIVATE_SESSIONS_UPDATE", data })

const mapStateToProps = (state: RootState) => ({
    browserSession: state.sessionsState.browserSession
  });

const mapDispatchToProps = {
  onBrowserRef: onBrowserRefUpdate
};

const authCondition = (authUser: any) => !!authUser;

export const Groups = compose(
  withAuthorization(authCondition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(GroupsComponent);
