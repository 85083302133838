import * as firebase from "firebase/app";
import { BreakdownURI } from "./BreakdownURI";
import { URISaveData, SavableURI, URISaveObject } from "./SavableURI";
import { privateURIRef, privateUsersRef, privateUIDRef, privateSessionsRef } from "./database";
import { mergeWith } from "lodash";
import { URIObject } from "src/constants/URIs";
import { saveTags, updateTags, removeTags } from "./tags";


// URI API

export function saveURI (uriObject: URIObject, title?: string, tags?: string[]) {
    // console.log(savableObject)
    const uriObj = new SavableURI(uriObject);

    const savableObject = uriObj
      .getURISaveObject({
        title: title ?
          title :
          uriObj.getSaveData().title
      })

    const savableFbPath = getFirebasePathFromSavableObject(savableObject);

    const saveData: URISaveData = {
      ...savableObject.saveData,
      tags
    };

    checkURIPrivateExistsAndUpdate(savableFbPath, saveData)

}



function checkURIPrivateExistsAndUpdate (savableFbPath: string, saveData: URISaveData) {
  // Firebase
  if (savableFbPath) {
    privateURIRef()
      .child(savableFbPath)
      .once('value', (data) => {
        // console.log('savableFbPath:', data.val())
        const dataVal = data.val();

        if (dataVal && dataVal.uid) {
          // console.log('checkURIPrivateExistsAndUpdate exists', dataVal.uid)

          const updatedData = mergeWith(
            {}, dataVal, saveData,
            (prop, val) => val === null ? prop : undefined
          )

          // Update data:
          updateURIPrivate(dataVal.uid, savableFbPath, {
            ...updatedData,
            tags: saveData.tags
          });

          // Update tags
          updateTags(dataVal.tags, saveData.tags!, dataVal.uid);

        } else {
          // console.log('checkURIPrivateExistsAndUpdate new', savableFbPath)
          const newUID = saveNewURIPrivate(savableFbPath, saveData)
          // Add tags
          saveTags(saveData.tags!, newUID)
        }
      })
  }
}


function updateURIPrivate (uid: string, savableFbPath: string, saveData: URISaveData) {
  console.log('updateURI', saveData)
  const uidData = { ...saveData };
  delete uidData.uid; // Avoid saving redundant uid

  privateUIDRef()
    .child(uid)
      .set({ _ : uidData });

  const privatePathRef = privateURIRef()
    .child(savableFbPath);

  privatePathRef.update({
    ...saveData,
    uid
  });
}

function saveNewURIPrivate (savableFbPath: string, saveData: URISaveData): string {
  const newPrivateUidRef = privateUIDRef()
    .push({ _ : {
      ...saveData,
      dateAdded: firebase.database.ServerValue.TIMESTAMP as string
    }});

  const newPrivateUID = newPrivateUidRef.key as string; // TODO: What if null?

  const privatePathRef = privateURIRef()
    .child(savableFbPath);

  privatePathRef.set({
    ...saveData,
    uid: newPrivateUID,
    dateAdded: firebase.database.ServerValue.TIMESTAMP as string
  });

  return newPrivateUID
}




export function deleteURI (fbPath: string) {
  // TODO: Remove unused tags

  privateURIRef()
    .child(fbPath)
      .once('value', (ref) => {
        const fbUid = ref.val() && ref.val().uid;
        const data = ref.val();

        if (fbUid) {
          privateUIDRef()
            .child(fbUid)
            .remove()
              .then(() => {
                privateURIRef()
                .child(fbPath)
                .remove();
              })

          removeTags(data.tags, fbUid)

        } else {
          console.error("No uid:", ref.val())
        }
      })
}

export function editUID (fbPath: string, data: URISaveData) {
  privateURIRef()
    .child(fbPath)
      .once('value', (ref) => {
        privateURIRef()
          .child(fbPath)
            .update(data);

        const fbUid = ref.val() && ref.val().uid;
        if (fbUid) {
          privateUIDRef()
            .child(fbUid)
            .child('_')
              .update(data);

        } else {
          console.error("No uid:", ref.val())
        }
      })
}





// Util

export function getFirebasePathFromSavableObject (savableObject: URISaveObject): string {
  const savablePath = savableObject.savablePath ?
    savableObject.savablePath :
    '';

  const savableUid = savableObject.savableUID ?
    '/'+savableObject.savableUID :
    '';

  return `${savableObject.savableTLD}/_paths${savablePath + savableUid}/_`
}

export function getSavableFromURI (uri, data?): URISaveObject {
  const uriObject = new BreakdownURI(uri)
    .getURIObject();

  return new SavableURI(uriObject)
    .getURISaveObject(data)
}


export function getUriObjectFromURI (uri): URIObject {
  return new BreakdownURI(uri)
    .getURIObject();
}



export function checkIfURLNotAllowed (url?: string) {
  // console.log('url', url)
  return url && (
    url.startsWith('chrome://') ||
    url.startsWith('chrome-extension://') ||
    url.startsWith('about://')
   ) ?
    true :
    false;
}

export function checkIfYoutube (uri): string | boolean {
  if (uri) {
    const savableObject = getSavableFromURI(uri);
    return savableObject.savableUID ? savableObject.savableUID : false;
  }
  return false;
}



// Sessions

export function removeBrowserWindow (windowId) {
  privateSessionsRef()
    .child('windows')
    .child(windowId)
      .remove()
}

export function removeBrowserTabHistory (windowId) {
  privateSessionsRef()
    .child('windows')
    .child(windowId)
    .child('history')
      .remove()
}


// Users

// User API
export const doCreateUser = (id: string, username: string, email: string) =>
  privateUsersRef().child(id).set({
    email,
    username
  });

export const onceGetUsers = () => privateUsersRef().once("value");
