import { auth } from "./firebase";
import * as routes from "../constants/routes";

// Sign Up
export const doCreateUserWithEmailAndPassword = (
  email: string,
  password: string
) => auth()
  .createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email: string, password: string) =>
  auth()
    .signInWithEmailAndPassword(email, password);

// Sign In with Google
const provider = new auth.GoogleAuthProvider();
export const doSignInWithGoogle = () =>
  auth()
    .signInWithPopup(provider);

// Sign out
export const doSignOut = (history) => {
  // Navigate away first, to avoid unbind errors
  history.push(routes.LANDING);
  auth().signOut();
};

// Password Reset
export const doPasswordReset = (email: string) =>
  auth()
    .sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = async (password: string) => {
  if (auth().currentUser) {
    await auth().currentUser!.updatePassword(password);
  }
  throw Error("No auth.currentUser!");
};
