import * as React from "react";

import { Card, CardPrimaryAction, CardMedia, CardActions, CardActionButtons, CardActionButton } from "@rmwc/card";
import { Typography } from "@rmwc/typography";

import { URISaveData } from "src/util/SavableURI";
import { OpenItemFn, DeleteItemFn } from "./TabCards";
import { ChipSet, Chip } from "@rmwc/chip";

export const TabCard = (props: { tabId: string, tab: URISaveData, groupId: string, openItem: OpenItemFn, deleteItem: DeleteItemFn, youtubeUID: string | boolean}) => {

  const { tabId, tab, groupId, openItem, deleteItem, youtubeUID } = props;

  // console.log('tags', tab, tab.tags)

  return (
    <Card>

      <CardPrimaryAction>

        <CardMedia
          sixteenByNine
          onClick={() => openItem(tabId, groupId, tab)}
          style={
            youtubeUID ? {
              backgroundImage:
                `url(https://img.youtube.com/vi/${youtubeUID}/0.jpg)`
            } : {
              backgroundImage: `url(${tab.favIconUrl})`
            }
          }
        />

        <div style={{ padding: '0 1rem 1rem 1rem' }}>

          <Typography use="headline6" tag="h2">
            {tab.title}
          </Typography>

          <Typography
                  use="body1"
                  tag="div"
                  theme="textSecondaryOnBackground"
                >
            {
              tab.tags ?
                <ChipSet>
                  {
                    tab.tags.map((tagName, key) => <Chip key={key} label={tagName} />)
                  }
                </ChipSet>
              : null
            }
          </Typography>

        </div>
      </CardPrimaryAction>

      <CardActions>

        <CardActionButtons>
          <a href={tab.uri} target="_blank" rel="noopener noreferrer">
            <CardActionButton
              href={tab.uri}
            >
              Open & remove
            </CardActionButton>
          </a>

          <CardActionButton danger onClick={() => deleteItem(tabId, groupId)}>Remove</CardActionButton>
        </CardActionButtons>

      </CardActions>

    </Card>
  )
}
