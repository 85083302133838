import { FirebaseAuthdata } from "src/constants/session";

// Actions
export const AUTH_USER_SET = 'AUTH_USER_SET'

interface SetAuthUser {
  type: typeof AUTH_USER_SET;
  payload: FirebaseAuthdata;
}

export function setAuthUser(authData: FirebaseAuthdata): SessionActionTypes {
  return {
    type: AUTH_USER_SET,
    payload: authData
  }
}

export type SessionActionTypes =
  SetAuthUser

// Reducers
export interface SessionState {
  authUser: FirebaseAuthdata | null;
}

const INITIAL_STATE = {
  authUser: null
};

const applySetAuthUser = (state: SessionState, action: any) => ({
  ...state,
  authUser: action.authUser
});

export function sessionReducer(state = INITIAL_STATE, action: SessionActionTypes) {
  switch (action.type) {
    case AUTH_USER_SET: {
      return applySetAuthUser(state, action);
    }
    default:
      return state;
  }
}
