import * as React from "react";
import { TabsData } from "..";
import { map } from "lodash";
import classNames from "classnames";

// import { Typography } from "@rmwc/typography";
import { Switch } from "@rmwc/switch";
import { ListDivider } from "@rmwc/list";

import './TabGroups.scss';
// import { URIDomains } from "src/components/URI/URIList/URIDomains";
import { TabGroup } from "./TabGroup";
import { ReverseObjArray } from "src/util";
import { SimpleMenu, MenuItem } from "@rmwc/menu";
import { Button } from "@rmwc/button";


export const TabGroups = (props: { tabs: TabsData}) => {
  const [cardChecked, setCardChecked] = React.useState(true);
  const [miniChecked, setMiniChecked] = React.useState(false);

  const groupClasses = classNames({
    'tab-groups': !cardChecked,
    'tab-groups--cards': cardChecked
  })

  const groups = props.tabs && props.tabs.groups ? ReverseObjArray(props.tabs.groups) as TabsData['groups'] : {}

  return (
    <div className="tab-groups__container">

      <div className="tab-groups__header">

        <div style={{ float: 'right', marginTop: '-32px'  }}>
          <Switch
            checked={cardChecked}
            onChange={evt => setCardChecked(evt.currentTarget.checked)}
            label="Cards"
            style={{ margin: 8 }}
          />
          <Switch
            checked={miniChecked}
            onChange={evt => setMiniChecked(evt.currentTarget.checked)}
            label="Mini"
            style={{ margin: 8 }}
          />
        </div>

        <SimpleMenu handle={<Button raised>Jump to Named Group</Button>}>

          {
            map(groups, (group, groupId) => {
              return group.name !== 'Group' ?
                <MenuItem key={groupId}>
                  <a key={groupId} href={`#${groupId}`}>{group.name}</a>
                </MenuItem>
                : null
            })
          }

        </SimpleMenu>

        <ListDivider style={{ paddingBottom: '1rem' }} />
      </div>

      <div className={groupClasses}>
        {
          groups ?
            map(groups, (group, groupKey) => {
              return <TabGroup key={groupKey} group={group} groupId={groupKey} tabs={props.tabs} miniChecked={miniChecked} cardChecked={cardChecked} />
            })
          : 'No Tabs saved'
        }
      </div>

    </div>
  );
};
