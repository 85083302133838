import * as React from "react";
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield'
import { LinearProgress } from '@rmwc/linear-progress';

import './URIInput.scss';

interface InterfaceProps {
  uri: string;
  title: string;
  loading: boolean;
  onEnter: () => void;
  onChangeURI: (uri: string) => void;
  onChangeTitle: (title: string) => void;
}

interface InterfaceState {
  uri: string;
  title: string;
}

export class URIInput extends React.Component<InterfaceProps, InterfaceState> {

  public static getDerivedStateFromProps (props, state) {
    if (props.title) {
      return { title: props.title }
    }
    if (props.uri) {
      return { uri: props.uri }
    }

    return null;
  }

  private static INITIAL_STATE = {
    uri: '',
    title: ''
  };

  constructor (props) {
    super(props);
    this.state = { ...URIInput.INITIAL_STATE };
  }

  public render() {
    const { loading } = this.props;
    return (
      <div className="uri-input">

        <Typography use="headline6" tag="span">Add URI</Typography>
        <Typography use="subtitle2" tag="div">Title: {loading ? <LinearProgress /> : this.state.title} </Typography>

        <Typography use="body1">
          <TextField
            label="URI"
            value={this.state.uri}
            onChange={event => this.setURI(event)}
            onKeyDown={event => this.handleKeyDown(event)}
            />
          <br />

          <TextField
            label="Title"
            disabled={loading || !this.state.uri}
            value={loading ? 'loading...' : this.state.title}
            onChange={event => this.changeTitle(event)}
            onKeyDown={event => this.handleKeyDown(event)}
            />
        </Typography>

      </div>
    );
  }

  private setURI(event: any) {
    const value = event.target.value;

    this.setState({
      uri: value,
    })

    // Trigger onchange for parent
    this.props.onChangeURI(value);
  }

  private changeTitle(event: any) {
    const value = event.target.value;

    this.setState({
      title: value
    })
    this.props.onChangeTitle(value)
  }

  private handleKeyDown(e) {
    // TODO: Check valid & required fields
    if (e.key === 'Enter') {
      this.props.onEnter();
    }
  }
}
