import * as React from "react";
import { URIDomains } from "./URIDomains";
import { Card } from "@rmwc/card";
import { Typography } from "@rmwc/typography";

import './URIList.scss';

interface InterfaceProps {
  uris?: any;
  open?: boolean;
}

export class URIsList extends React.Component<InterfaceProps, {}> {

  public static defaultProps = {
    open: false,
    pathsOpen: false
  }

  public render() {
    const { uris, open, pathsOpen }: any = this.props;
    return (
      <Card className="uri-list">

        <Typography use="headline6">Your URIs</Typography>

        <URIDomains uris={uris} open={open} pathsOpen={pathsOpen} />

      </Card>
    );
  }
}
