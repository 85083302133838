import * as React from "react";
import { TabData } from "src/constants/sessions";
import classNames from 'classnames';
import { Avatar } from "@rmwc/avatar";

export const PinnedTabComponent = (props: { tab: TabData, tabId: string, selected: boolean }) => {
  const tabClasses = classNames({
    'tab-elem-pinned': true,
    'border-highlight': props.selected
  })
  return (
    <div className={tabClasses}>

      <a
        href={props.tab.uri}
        target='_blank'
        rel="noopener noreferrer"
      >
        {
          props.tab.favIconUrl ?
            <Avatar
              className="tab-favicon"
              src={props.tab.favIconUrl}
              size="medium"
              name="URI"
            /> :
            null
        }
      </a>

    </div>
  );
};
