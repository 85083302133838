import * as React from "react";
import { Component } from 'react';

import { privateTagsRef } from "src/util/database";
import { withAuthorization } from "../Auth/Session/withAuthorization";

import { GridList, GridTile, GridTilePrimary, GridTileSecondary, GridTileIcon, GridTileTitle  } from "@rmwc/grid-list";
import { compose } from "recompose";
import { map } from "lodash";
import { Grid, GridCell } from "@rmwc/grid";

import './Tags.scss';
import classNames from "classnames";
import TagUIDS from "./TagUIDS";

// function getParentOffsets(el): number {
//   if (el.offsetParent) {
//       return el.offsetParent.offsetTop + getParentOffsets(el.offsetParent);
//   } else {
//       return 0;
//   }
// }

interface TagsState {
  tags: {
    [tagName: string]: {
      dateUpdated: number;
      uids: {
        [uid: string]: {
          dateCreated: number;
        }
      }
    }
  },
  expanded: boolean,
  currentTag: string;
  currentTagCoords: {
    [key: string]: any
  }
}

class TagsPageComponent extends Component<{}, TagsState> {

  constructor(props, state) {
    super(props);
    this.state = {
      tags: {},
      currentTag: '',
      expanded: false,
      currentTagCoords: { }
    }
  }

  public componentDidMount() {

    privateTagsRef()
      .on("value", (snapshot) => {
        this.setState({
          tags: snapshot.val()
        })
      });
  }

  public selectTile (key, evt) {
    console.log('selectTile', key, evt);
    // console.log(evt.target);
    const element = document.getElementById(key);
    // const boundingBox = evt.target.getBoundingClientRect();
    console.dir(element);
    console.dir(element!.clientWidth);
    console.log(evt.target.getBoundingClientRect());
    const firstCh = element!.firstChild as any;
    this.setState({
      expanded: false,
      currentTag: key,
      currentTagCoords: {
        x: element!.offsetLeft + 2, // Plus some dumb shit
        y: element!.offsetTop,
        height: firstCh.clientHeight + 1, // boundingBox.height,
        width: firstCh.clientWidth -1 // boundingBox.width
      }
    })

  }

  public expandTile (evt, x, y) {
    const boundingBox = evt;
    console.log('expandTile', boundingBox);
    if (!this.state.expanded) {
      this.setState({
        expanded: true,
        currentTagCoords: {
          ...this.state.currentTagCoords
        }
      })
    } else {
      const element = document.getElementById(this.state.currentTag);
      const firstCh = element!.firstChild as any;
      this.setState({
        expanded: false,
        currentTagCoords: {
          ...this.state.currentTagCoords,
          height: firstCh.clientHeight, // boundingBox.height,
          width: firstCh.clientWidth // boundingBox.width
        }
      })
    }

  }

  public render() {
    const { tags, currentTag, expanded } = this.state;
    const { x, y, height, width } = this.state.currentTagCoords;
    const boxClasses = classNames({
      'tag-item__box': true,
      'tag-item__expanded': expanded
    })
    const currentSelected = tags && tags[currentTag] ? tags[currentTag] : null;
    return (
      <div className="tag-page">
        <div className="tag-item-container">

          <GridTile
            className={boxClasses}
            style={{ left: expanded ? 0 : x, top: expanded ? window.scrollY - 128 : y, height: height, width: width }}
            onClick={(e) => this.expandTile(e, x, y)}
          >
            <div>
              <TagUIDS tagUids={currentSelected && currentSelected.uids} selected={currentSelected} />
            </div>
            <GridTileSecondary>
              <GridTileIcon icon="info" />
              <GridTileTitle>{currentTag}</GridTileTitle>
            </GridTileSecondary>
          </GridTile>

        </div>
        <Grid>
          <GridCell
            span={12}
            align="middle"
          >
            <GridList
              tileAspect="16x9"
            >
              {
                map(tags, (tag, key) => {
                  return (
                    <GridTile
                      key={key}
                      id={key}
                      className="tag-grid-tile"
                      onClick={(e) => this.selectTile(key, e)}
                    >
                      <GridTilePrimary>
                        <div>{tag.uids ? null : '-no uids-'}</div>
                      </GridTilePrimary>
                      <GridTileSecondary>
                        <GridTileIcon icon="info" />
                        <GridTileTitle>{key + (tag.uids ? ` (${Object.keys(tag.uids).length})` : '')}</GridTileTitle>
                      </GridTileSecondary>
                    </GridTile>
                  )
                })
              }
            </GridList>
          </GridCell>
        </Grid>
      </div>
    );
  }

};

const authCondition = (authUser: any) => !!authUser;

export const TagsPage = compose(
  withAuthorization(authCondition)
)(TagsPageComponent as any);
