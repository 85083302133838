import * as React from "react";
import { compose } from "recompose";

import { withAuthorization } from "../Auth/Session/withAuthorization";


class HomeComponent extends React.Component<any> {
  public render() {

    return (
      <div className="home-div">

        <h5>Home</h5>

      </div>
    );
  }
}

const authCondition = (authUser: any) => !!authUser;

export const Home = compose(
  withAuthorization(authCondition)
)(HomeComponent);
