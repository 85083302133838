import * as React from "react";
import { TabData } from "src/constants/sessions";
import classNames from 'classnames';
import { Avatar } from "@rmwc/avatar";

import './Tab.scss';

export const TabComponent = (props: { tab: TabData, tabId: string, selected: boolean }) => {
  const tabClasses = classNames({
    'tab-elem': true,
    'border-highlight': props.selected
  })

  // get savable uid
  return (
    <div className={tabClasses}>

      <a
        href={props.tab.uri}
        target='_blank'
        rel="noopener noreferrer"
      >
        {
          props.tab.favIconUrl ?
            <Avatar
              className="tab-favicon"
              src={props.tab.favIconUrl}
              size="medium"
              name="URI"
            /> :
            null
        }
        {
          props && props.tab ?
            <div className="tab-title">{props.tab.title} ({props.tabId})</div>
            : 'ERROR'
        }
      </a>

    </div>
  );
};
