import { ofType, Epic } from 'redux-observable';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { of, Observable, concat, fromEventPattern } from 'rxjs';

import { privateURIRef } from 'src/util/database';
import { Action } from '.';
import { onUpdateUrisAction, URIS_WATCH_UPDATE, WatchUpdateURIS } from 'src/reducers/uris';


export const watchUpdateURIEpic: Epic = (action$: Action, store): Observable<Action> => action$.pipe(
  ofType(URIS_WATCH_UPDATE),
  mergeMap((act: WatchUpdateURIS) =>
    concat(
      fromEventPattern(
        handler => privateURIRef().on('value', handler),
        handler => privateURIRef().off('value', handler),
      ).pipe(
        map((snap: any) => snap.val()),
        mergeMap(snap =>
          of(onUpdateUrisAction(snap))
        ),
        catchError((err) =>
          of(onUpdateUrisAction(err))
        )
      )
    )
  )
)
