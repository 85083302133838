import * as React from "react";
import { TabGroupI, TabsData } from "..";
import { map } from "lodash";
import { Avatar } from "@rmwc/avatar";

import './TabGroups.scss';
import { Card, CardActions, CardActionIcons } from "@rmwc/card";
import { List, CollapsibleList, SimpleListItem } from "@rmwc/list";
import { TabItem } from "./TabItem";
import { OpenItemFn, DeleteItemFn } from "./TabCards";

export const TabGroupCardList = (props: { groupId: string, group: TabGroupI, tabs: TabsData, openItem: OpenItemFn, deleteItem: DeleteItemFn }) => {

  // const { group, groupId, tabs } = props;
  const { groupId, group, tabs, openItem, deleteItem } = props;


  const containsTabs = tabs && Object.keys(tabs).length > 0;

  // console.log("tabs", tabs)

  return (
    <Card className="tab-groups__item">
      <List twoLine avatarList>
        {
          map(group.tabs, (tabId, tabKey) => {
            //
            // console.log('id', tabId)
            // console.log('tabKey', tabKey)
            // console.log('tabs', tabs)
            // console.log('tabs.uid', tabs.uid)

            const tab = tabs && tabs.uid[tabId];
            //
            // console.log('tab', tab)
            //
            return tab ?
              <CollapsibleList
                key={tabKey}
                handle={
                  <SimpleListItem
                    text={tab.title}
                    graphic={
                      <Avatar
                        src={tab.favIconUrl!}
                        size="xsmall"
                        name={tabId}
                      />
                    }
                    metaIcon="chevron_right"
                  />
                }
              >
                <div>
                  <TabItem tab={tab} tabId={tabId} groupId={groupId}/>
                </div>
              </CollapsibleList>
              : '-error-'
          })
        }
      </List>
      <CardActions>
        <CardActionIcons>

        </CardActionIcons>
      </CardActions>
    </Card>

  );
};

    // { containsTabs ? null : <CardActionIcon icon="close" onClick={() => deleteGroup(groupId)} /> }
