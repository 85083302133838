import * as firebase from "firebase/app";

// Private
// NOTE: Only call when authorized!

function userPrivateRef () {
  const privateRef = firebase.database()
    .ref('private');

  return privateRef
    .child(firebase.auth().currentUser!.uid);
}

// Session
export function privateSessionsRef () {
  return userPrivateRef()
    .child('sessions/123');
}

export function privateSessionURIRef () {
  return privateSessionsRef()
    .child('uris')
}

export function privateTabsRef () {
  return userPrivateRef()
    .child('tabs');
}


// URI
export function privateURIRef () {
  return userPrivateRef()
    .child('uri');
}

// UID
export function privateUIDRef () {
  return userPrivateRef()
    .child('uids');
}

// Tags
export function privateTagsRef () {
  return userPrivateRef()
    .child('tags');
}

// Users
export function privateUsersRef () {
  return firebase.database()
    .ref('users');
}






// Public

export function publicURIRef () {
  return firebase.database()
    .ref('public/uri');
}

export function publicUIDRef () {
  return firebase.database()
    .ref('public/uids');
}



// UTIL <DANGER>

//
// function moveStuff () {
//
//   const uids = db.ref().child('uri');
//   const newRef = db.ref().child('public/uri')
//
//   uids.once('value', (snap) => {
//     newRef.set(snap.val(), (error) => {
//        if( error && typeof(console) !== 'undefined' && console.error ) {
//          console.error(error);
//        } else {
//          uids.remove();
//        }
//     })
//   })
// }
