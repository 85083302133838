import * as React from "react";
import { map, reduce } from 'lodash';
import { WindowState, SessionsData, TabCollection } from "src/constants/sessions";
import { TabComponent } from "./Tab";
import { removeBrowserWindow } from "src/util/uri";
import { HistoryComponent } from "./History";
import Moment from 'react-moment';

import { Avatar } from "@rmwc/avatar";
import { Card, CardActions, CardActionButtons, CardActionButton } from "@rmwc/card";
import { ListDivider  } from "@rmwc/list";
import { Typography } from "@rmwc/typography";
import { PinnedTabComponent } from "./PinnedTab";

import './Window.scss';
import { Elevation } from "@rmwc/elevation";

interface Props {
  window: WindowState,
  windowId: string,
  selectedTab: number,
  currentSession: SessionsData
}

interface State {
  historyOpen: boolean;
  elevation: number;
}

export class WindowComponent extends React.Component<Props, State> {

  constructor(props, state){
    super(props, state)
    this.state = {
      historyOpen: false,
      elevation: 3
    }
  }

  public toggleHistory () {
    this.setState({
      historyOpen: !this.state.historyOpen
    })
  }

  public splitPinnedTabs (tabs: TabCollection) {
    const onlyTabs = reduce(tabs, (memo, tab, key) => {
      if (!tab.pinned) {
        memo[key] = tab;
      }
      return memo
    }, {})

    const onlyPinned = reduce(tabs, (memo, tab, key) => {
      if (tab.pinned) {
        memo[key] = tab;
      }
      return memo
    }, {})

    return {
      tabs: onlyTabs,
      pinned: onlyPinned
    }
  }

  public render() {
    const { window, windowId, selectedTab } = this.props;
    const { historyOpen, elevation } = this.state;
    const setElevation = (e) => this.setState({
      elevation: e
    });

    if (selectedTab &&
        window &&
        window.tabs &&
        window.tabs[selectedTab]
      ) {
      const { pinned, tabs } = this.splitPinnedTabs(window.tabs)
      const uri = window.tabs[selectedTab].uri;
      const faviconUrl = window.tabs[selectedTab].favIconUrl;

      return (
        <Elevation
          z={elevation}
          transition
          onMouseOver={() => setElevation(18)}
          onMouseOut={() => setElevation(3)}
          wrap
        >
          <Card className="window-elem">

              <div>
                <a href={uri} target='_blank' rel="noopener noreferrer">
                  {
                    window.tabs[selectedTab].favIconUrl ?
                      <Avatar
                        src={faviconUrl!}
                        size="xlarge"
                        name="URI"
                      /> :
                      null
                  }
                  <Typography  use="headline4" className="window-title">
                    {window.tabs[selectedTab].title}
                  </Typography>
                </a>
              </div>

              <ListDivider />

              <div className="window-tabs-pinned">
                {
                  map(pinned, (tabItem, tabId: string) => {
                    return (
                      <PinnedTabComponent
                        key={tabId}
                        tab={tabItem}
                        tabId={tabId}
                        selected={selectedTab === parseInt(tabId, 10)}
                      />
                    )
                  })
                }
              </div>

              <div>
                {
                  map(tabs, (tabItem, tabId: string) => {
                    return (
                      <TabComponent
                        key={tabId}
                        tab={tabItem}
                        tabId={tabId}
                        selected={selectedTab === parseInt(tabId, 10)}
                      />
                    )
                  })
                }
              </div>

              <ListDivider />

              <CardActions>
                <CardActionButtons className="window-elem__actions">
                  {
                    window.history ?
                      <CardActionButton onClick={() => this.toggleHistory()}>History</CardActionButton> :
                      null
                  }
                  <CardActionButton danger outlined className="delete-button" onClick={() => deleteWindow(windowId)}>
                    Delete
                  </CardActionButton>
                </CardActionButtons>
              </CardActions>

              {
                historyOpen ?
                  <HistoryComponent tabs={window.history} selectedTab={selectedTab} windowId={windowId} /> :
                  null
              }

              <small>WindowId ({windowId})</small>
              { window.dateLastActivated ? <small>Updated <Moment fromNow>{window.dateLastActivated}</Moment></small> : null }

          </Card>
        </Elevation>
      );
    } else if (!window.tabs && !window.history && selectedTab) {
      return null
    } else {
      return (
        <Card className="window-elem">
        <CardActions>
          <CardActionButtons className="window-elem__actions">
            {
              window.history ?
                <CardActionButton onClick={() => this.toggleHistory()}>History</CardActionButton> :
                null
            }
            <CardActionButton danger outlined className="delete-button" onClick={() => deleteWindow(windowId)}>Delete</CardActionButton>
          </CardActionButtons>
        </CardActions>
           { !window.tabs && window.history ?
              <div>
                Only History ({selectedTab})
                { window.dateLastActivated ? <small>Updated <Moment fromNow>{window.dateLastActivated}</Moment></small> : null }
              </div>
             : null
           }
           <hr />
           { window.tabs && !window.history ?
              <div>
                Only Tabs ({selectedTab})
                { window.dateLastActivated ? <small>Updated <Moment fromNow>{window.dateLastActivated}</Moment></small> : null }
              </div>
             : null
           }
           { window.tabs && window.history ?
              <div>
                Tabs & History ({selectedTab})
                { window.dateLastActivated ? <small>Updated <Moment fromNow>{window.dateLastActivated}</Moment></small> : null }
              </div>
             : null}
        </Card>
      )
    }
  }

}

function deleteWindow (windowId) {
  removeBrowserWindow(windowId);
}
