import * as React from "react";
import { map } from "lodash";

import { getSavableFromURI, getFirebasePathFromSavableObject, checkIfYoutube } from "src/util/uri";
import { privateTabsRef } from "src/util/database";
import { TabGroupI, TabsData } from "..";
import './TabCards.scss';

import { URISaveData } from "src/util/SavableURI";
import { TabCardMini } from "./TabCardMini";
import { TabCard } from "./TabCard";
import { TabGroupCardList } from "./TabGroupCardList";

export type OpenItemFn = (
  tabId: string,
  groupId: string,
  tab: URISaveData
) => void;

export type DeleteItemFn = (
  tabId: string,
  groupId: string
) => void;


export const TabCards = (props: { group: TabGroupI, groupId: string, tabs: TabsData, miniChecked: boolean, cardChecked: boolean; minimized: boolean }) => {

  function deleteItem (tabId: string, groupId: string) {
    // console.log('deleteItem', tabId, groupId)
    if (props.tabs.uid[tabId]) {
      const saveObject = getSavableFromURI(props.tabs.uid[tabId].uri)
      const fbpath = getFirebasePathFromSavableObject(saveObject)

      privateTabsRef().child('uri').child(fbpath).remove();
      privateTabsRef().child('uid').child(tabId).remove();
      privateTabsRef().child('groups').child(groupId).child('tabs').child(tabId).remove();
    } else {
      // console.log("orphan", tabId, groupId)
      privateTabsRef().child('groups').child(groupId).child('tabs').child(tabId).remove();
    }
  }


  function openItem (tabId: string, groupId: string, tab: URISaveData) {
    // console.log('tab', tab)
    const saveObject = getSavableFromURI(tab.uri)
    const fbpath = getFirebasePathFromSavableObject(saveObject)

    privateTabsRef().child('uri').child(fbpath).remove();
    privateTabsRef().child('uid').child(tabId).remove();
    privateTabsRef().child('groups').child(groupId).child('tabs').child(tabId).remove();

    // console.log('open tab', tab)
    window.open(tab.uri, '_blank');

  }

  const { group, groupId, tabs, cardChecked, miniChecked } = props;

  // console.log('grp', group)
  // console.log('tabs', tabs)

  return (
    <div className="tab-cards">
      {
        !cardChecked ?
          <TabGroupCardList
            groupId={groupId}
            group={group}
            tabs={tabs}
            openItem={openItem}
            deleteItem={deleteItem}
          />
        :
          map(group.tabs, (tabId, tabKey) => {
            const tab = tabs && tabs.uid[tabId];
            const uri = tab && tab.uri
            const youtubeUID = checkIfYoutube(uri);

            if (miniChecked) {
              return (
                <TabCardMini key={tabKey} tabId={tabId} tab={tab} groupId={groupId} openItem={openItem} deleteItem={deleteItem} youtubeUID={youtubeUID} />
              )
            } else {
              return (
                <TabCard key={tabKey} tabId={tabId} tab={tab} groupId={groupId} openItem={openItem} deleteItem={deleteItem} youtubeUID={youtubeUID} />
              )
            }
          })
      }
    </div>
  );
};


export {}
