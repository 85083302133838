import * as React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withAuthorization } from "../Auth/Session/withAuthorization";
import { URIsList } from "./URIList/URIList";
import { RootState } from "src/reducers";
import { URIs } from "src/constants/URIs";
import { privateURIRef } from "src/util/database";
import { URIAdd } from "./URIAdd/URIAdd";

import './URI.scss'
import { onUpdateUrisAction, watchUpdateUrisAction } from "src/reducers/uris";

interface InterfaceProps {
  uris?: URIs;
  onSetUris: typeof onUpdateUrisAction;
}

class URIsComponent extends React.Component<InterfaceProps> {

  public componentDidMount() {

    const { watchUpdateUris }: any = this.props;
    watchUpdateUris();

  }

  public componentWillUnmount () {
    privateURIRef().off("value");
  }

  public render() {
    const { uris } = this.props;

    return (
      <div className="uri-container">

        <URIAdd />

        <URIsList uris={uris} open pathsOpen />

      </div>
    );
  }

}



const mapStateToProps = (state: RootState) => ({
  uris: state.uriState.uris
});

const mapDispatchToProps = {
  watchUpdateUris: watchUpdateUrisAction
};

const authCondition = (authUser: any) => !!authUser;

export const URIsPage = compose(
  withAuthorization(authCondition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(URIsComponent as any);
