import * as React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { map } from "lodash";
import classNames from 'classnames';

import * as routes from "../../constants/routes";
import { SignOutButton } from "../Auth/SignOut";

import { TabBar, Tab } from '@rmwc/tabs';
import { ThemeProvider } from '@rmwc/theme';
import { SimpleMenuSurface }   from '@rmwc/menu'
import { TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle, TopAppBarActionItem } from '@rmwc/top-app-bar';
import { SignInGoogle } from "../Auth/SignIn/SignInGoogle";

import './Navigation.scss';

const routeIndexes = {
  [routes.HOME]: 0,
  [routes.URIS]: 1,
  [routes.TAGS]: 2,
  [routes.SESSIONS]: 3,
  [routes.GROUPS]: 4,
  [routes.ACCOUNT]: 5
}

const NavigationComponent = ({ authUser, history, location }: any) => (
  <div>{authUser ? <NavigationAuth history={history} location={location} /> : <NavigationNonAuth history={history} />}</div>
);

class NavigationAuth extends Component <{ history: any, location: any }, {activeTab: number}> {

  constructor (props) {
    super(props)
    this.state = {
      activeTab: this.getRouteFromName()
    }
  }

  public getRouteFromName (): number {
    const pathName = this.getRouteFromCurrentLocation();
    return routeIndexes[pathName]
  }

  public getRouteFromCurrentLocation (): string {
    // console.log(this.props.location.pathname)
    return this.props.location.pathname;
  }

  public routeChange = (route: string) => {
    this.props.history.push(route);
  }

  public setActiveTab = (activeTab: number) => {
    this.setState({
      activeTab
    })
  }

  public render () {

    const { activeTab } = this.state;
    const titleClasses = classNames({
      'navigation-title': true,
      'navigation-title__clickable': true
    })

    return (
      <TopAppBar className="navigation-container">

        <TopAppBarRow>

          <TopAppBarSection alignStart>
            <TopAppBarTitle>

              <div onClick={() => this.routeChange('/')} className={titleClasses}>
                Stuff
              </div>

            </TopAppBarTitle>
          </TopAppBarSection>

          <TopAppBarSection alignEnd>

            <SimpleMenuSurface handle={<TopAppBarActionItem icon="more_vert" />}>
              <SignOutButton history={this.props.history} />
            </SimpleMenuSurface>

          </TopAppBarSection>

        </TopAppBarRow>

        <TopAppBarRow>

          <div className="navigation-tabs">
            <ThemeProvider
              options={{ primary: 'white', onSurface: 'white' }}
              wrap
            >

              <TabBar
                activeTabIndex={activeTab}
                onActivate={evt => this.setActiveTab(evt.detail.index)}
              >
                  {
                    map(routeIndexes, (routeIndex, routeName) => {
                      const strippedName = routeName.substr(1);
                      const routeTitle = strippedName // ? strippedName : 'Home'
                      return <Tab key={routeName} onInteraction={() => this.routeChange(routeName)}>{routeTitle}</Tab>
                    })
                  }
              </TabBar>

            </ThemeProvider>
          </div>

        </TopAppBarRow>

      </TopAppBar>
    )
  }
};

const NavigationNonAuth = ({ history }) => {

  return (
    <div className="navigation-container">

      <TopAppBar>
       <TopAppBarRow>

         <TopAppBarSection alignStart>
           <TopAppBarTitle>Stuff</TopAppBarTitle>
         </TopAppBarSection>

         <TopAppBarSection alignEnd>
            <SimpleMenuSurface handle={<TopAppBarActionItem icon="person" />}>
              <SignInGoogle history={history} />
            </SimpleMenuSurface>
         </TopAppBarSection>

       </TopAppBarRow>
      </TopAppBar>

    </div>
  )

};

const mapStateToProps = (state: any) => ({
  authUser: state.sessionState.authUser
});

export const Navigation = connect(mapStateToProps)(withRouter(NavigationComponent));
