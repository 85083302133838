import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { App } from "./components/App";

import registerServiceWorker from "./registerServiceWorker";
import { configureStore } from "./store";
import { RMWCProvider } from '@rmwc/provider';
import { ThemeProvider } from "@rmwc/theme";

import '../node_modules/material-components-web/dist/material-components-web.min.css';
import '@rmwc/avatar/avatar.css';
import '@rmwc/circular-progress/circular-progress.css';
import '@rmwc/icon/icon.css';
import '@rmwc/list/collapsible-list.css';

ReactDOM.render(
  <Provider store={configureStore()}>
    <RMWCProvider
      ripple={true}
    >
      <ThemeProvider
          options={{
            primary: '#990000',
            secondary: '#323ea8',
            error: '#f125f5',
            // primary: '#24aee9',
            // secondary: '#e539ff',
            // error: '#b00020',
            // background: '#212121',
            // surface: '#37474F',
            // onPrimary: 'rgba(255,255,255,.87)',
            // onSecondary: 'rgba(0,0,0,0.87)',
            // onSurface: 'rgba(255,255,255,.87)',
            // onError: '#fff',
            // textPrimaryOnBackground: 'rgba(255, 255, 255, 1)',
            // textSecondaryOnBackground: 'rgba(255, 255, 255, 0.7)',
            // textHintOnBackground: 'rgba(255, 255, 255, 0.5)',
            // textDisabledOnBackground: 'rgba(255, 255, 255, 0.5)',
            // textIconOnBackground: 'rgba(255, 255, 255, 0.5)',
            // textPrimaryOnLight: 'rgba(0, 0, 0, 0.87)',
            // textSecondaryOnLight: 'rgba(0, 0, 0, 0.54)',
            // textHintOnLight: 'rgba(0, 0, 0, 0.38)',
            // textDisabledOnLight: 'rgba(0, 0, 0, 0.38)',
            // textIconOnLight: 'rgba(0, 0, 0, 0.38)',
            // textPrimaryOnDark: 'white',
            // textSecondaryOnDark: 'rgba(255, 255, 255, 0.7)',
            // textHintOnDark: 'rgba(255, 255, 255, 0.5)',
            // textDisabledOnDark: 'rgba(255, 255, 255, 0.5)',
            // textIconOnDark: 'rgba(255, 255, 255, 0.5)'
          }}
        >
        <App />
      </ThemeProvider>
    </RMWCProvider>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
