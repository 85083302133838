import * as React from "react";

import { Card, CardPrimaryAction, CardMedia, CardActions, CardMediaContent, CardActionIcons, CardActionIcon } from "@rmwc/card";
import { Typography } from "@rmwc/typography";

import { URISaveData } from "src/util/SavableURI";
import { OpenItemFn, DeleteItemFn } from "./TabCards";
import { Avatar } from "@rmwc/avatar";

export const TabCardMini = (props: { tabId: string, tab: URISaveData, groupId: string, openItem: OpenItemFn, deleteItem: DeleteItemFn, youtubeUID: string | boolean}) => {

  const { tabId, tab, groupId, openItem, deleteItem, youtubeUID } = props;

  return (
    <Card style={{ width: '12.5rem' }}>
      { tab ?
          <CardPrimaryAction>
            <CardMedia
              square
              onClick={() => openItem(tabId, groupId, tab)}
              style={ youtubeUID ? {
                  backgroundImage: `url(https://img.youtube.com/vi/${youtubeUID}/0.jpg)`,
                  backgroundSize: '180%'
                } : tab ? {
                  backgroundImage: `url(${tab.favIconUrl})`
                } : {}
              }
            >
            <CardMediaContent>
              <Typography
                use="subtitle2"
                tag="div"
                theme="textPrimaryOnDark"
                style={{
                  padding: '0.5rem 1rem',
                  backgroundImage:
                    'linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.5) 100%)',
                  bottom: '0',
                  left: '0',
                  right: '0',
                  position: 'absolute'
                }}
              >
                { tab && tab.title}
              </Typography>
            </CardMediaContent>
          </CardMedia>
        </CardPrimaryAction>
        :
        null
      }

      <CardActions>
        <CardActionIcons>

          { tab ?
            <Avatar
              src={tab.favIconUrl!}
              name={tab.title}
              size="large"
              interactive
            />
            : null
          }

          <CardActionIcon
            icon="close"
            style={{ color: 'red' }}
            onClick={() => deleteItem(tabId, groupId)}
          />

        </CardActionIcons>
      </CardActions>
    </Card>
  )
}
