import * as React from "react";
import { editUID, deleteURI } from "src/util/uri";
import { privateURIRef } from "src/util/database";
import { Button } from "@rmwc/button";
import { TextField } from "@rmwc/textfield";

import './URIEdit.scss';

interface InterfaceProps {
  title: string;
  fbPath: string;
  fbUid: string;
  uri: string;
}

interface InterfaceState {
  title: string;
  isInvalid: boolean;
}

export class URIEdit extends React.Component<
  InterfaceProps,
  InterfaceState
> {
  private static INITIAL_STATE = {
    title: "",
    isInvalid: false
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  constructor(props: InterfaceProps) {
    super(props);
    this.state = {
      ...URIEdit.INITIAL_STATE,
      title: props.title
    };
  }

  public componentDidMount() {
    privateURIRef()
      .child(this.props.fbPath)
        .on('value', (uri) => {
          const uriData = uri.val();
          // Exists
          if (uriData) {
            // console.log('exists', uriData.title);
            this.setState({
              title: uriData.title
            })
          } else {
            this.setState({
              title: this.props.title
            })
          }
        })
  }

  public componentWillUnmount () {
    privateURIRef()
      .child(this.props.fbPath)
        .off("value")
  }


  public onSubmit(e: any) {
    e.preventDefault();
    const { title } = this.state;

    editUID(this.props.fbPath, {
      title,
      uri: this.props.uri
    })
  }

  public delete(e: any) {
    deleteURI(this.props.fbPath);
  }

  public render() {
    const { title, isInvalid } = this.state;

    return (
      <div className="uri-edit">

        <form onSubmit={(e) => this.onSubmit(e)}>

          <TextField
            label="Title"
            value={title}
            onChange={e => this.setStateWithEvent(e, "title")}
            type="text"
            placeholder="Title"
          />

          <Button raised disabled={isInvalid} type="submit" className="uri-save-button">
            Save
          </Button>

        </form>

        <Button danger raised onClick={(e) => this.delete(e)} className="uri-delete-button">Delete</Button>

      </div>
    );
  }

  private setStateWithEvent(e: any, columnType: string) {
    this.setState({
      ...URIEdit.propKey(columnType, (e.target as any).value),
      isInvalid: (e.target as any).value ? false : true
    });
  }
}
