import * as React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withAuthorization } from "../Auth/Session/withAuthorization";
import { UserList } from "./UserList";
import { onceGetUsers } from "src/util/uri";


class UsersComponent extends React.Component {
  public componentDidMount() {
    const { onSetUsers }: any = this.props;

    onceGetUsers().then(snapshot => onSetUsers(snapshot.val()));
  }

  public render() {
    const { users }: any = this.props;

    return (
      <div>
        <h1>Users</h1>
        {!!users && <UserList users={users} />}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  users: state.userState.users
});

const mapDispatchToProps = (dispatch: any) => ({
  onSetUsers: (users: any) => dispatch({ type: "USERS_SET", users })
});

const authCondition = (authUser: any) => !!authUser;

export const Users = compose(
  withAuthorization(authCondition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UsersComponent as any);
