import * as React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { map } from 'lodash';
import { withAuthorization } from "../Auth/Session/withAuthorization";
import { BrowserSession } from "src/constants/sessions";
import { RootState } from "src/reducers";
import { WindowComponent } from "./Window";
import './Sessions.scss';
import { privateSessionsRef, privateSessionURIRef } from "src/util/database";
import { URIDomains } from "../URI/URIList/URIDomains";
import { URIs } from "src/constants/URIs";

import { CircularProgress } from "@rmwc/circular-progress";
import { Typography } from "@rmwc/typography";
import { ListDivider  } from "@rmwc/list";
import { Button } from "@rmwc/button";

interface SessionsProps {
  browserSession: BrowserSession;
  onBrowserRef: typeof onBrowserRefUpdate;
}

interface SessionsState {
  loading: boolean;
  uris: URIs;
}

class SessionsComponent extends React.Component<SessionsProps, SessionsState> {
  constructor (props, state) {
    super(props, state);
    this.state = {
      loading: true,
      uris: {}
    }
  }

  public componentDidMount() {
    const { onBrowserRef }: any = this.props;

    privateSessionsRef()
      .on("value", (snapshot) => {
        onBrowserRef(snapshot.val());
        this.setState({
          loading: false
        })
      });

    privateSessionURIRef()
      .on("value", (snapshot) => {
        this.setState({
          uris: snapshot.val()
        })
      })
  }

  public componentWillUnmount () {
    privateSessionsRef().off("value");
    privateSessionURIRef().off("value");
  }

  public clearURIHistory () {
    privateSessionURIRef().remove();
  }

  public render() {
    const { browserSession } = this.props;
    const { loading, uris } = this.state;

    return (
      <div className="sessions-container">

        <Typography use="body1" tag="p" theme="textSecondaryOnBackground">
          BrowserId: 123
        </Typography>

        <ListDivider />

        <Typography use="headline5"> Windows </Typography>

        <div className="sessions-windows-list">

          { browserSession && browserSession.windows ?
              map(browserSession.windows, (windowItem, windowId) => {
                // console.log(windowItem, windowId);
                const selectedTabId = windowItem && windowItem.highlightedTabId;
                return (
                  <WindowComponent
                    key={windowId}
                    window={windowItem}
                    windowId={windowId}
                    selectedTab={selectedTabId}
                    currentSession={browserSession.windows[windowId]}
                  />
                )
              })
            : loading ?
                <CircularProgress /> :
                <div>No Session data, install Extension, and enable sync</div>
          }

        </div>

        {
          !loading ?
            <div>

              <Typography use="headline6">
                History of URIs
              </Typography>

              <URIDomains uris={uris} open={false} pathsOpen={false} />

              <Button onClick={() => this.clearURIHistory()} danger raised>
                Clear URI history
              </Button>

            </div>
            : null
        }

      </div>
    );
  }
}

const onBrowserRefUpdate = (data: any) => ({ type: "PRIVATE_SESSIONS_UPDATE", data })

const mapStateToProps = (state: RootState) => ({
    browserSession: state.sessionsState.browserSession
  });

const mapDispatchToProps = {
  onBrowserRef: onBrowserRefUpdate
};

const authCondition = (authUser: any) => !!authUser;

export const Sessions = compose(
  withAuthorization(authCondition),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SessionsComponent);
