import * as React from "react";
import { map } from "lodash";
import { connect } from "react-redux";

import { RootState } from "src/reducers";
import { fetchUIDs } from "src/reducers/uids";
import { URISaveData } from "src/util/SavableURI";
import { CircularProgress } from "@rmwc/circular-progress";
import { Card, CardPrimaryAction, CardMedia, CardActions, CardActionButtons, CardActionButton, CardActionIcons, CardActionIcon } from "@rmwc/card";
import { Typography } from "@rmwc/typography";
import { checkIfYoutube } from "src/util/uri";

interface TagUIDSProps {
  selected: any;
  tagUids: {
    [uid: string]: {
      dateCreated: number;
    };
  } | null;
  uids: {
    [uid: string]: URISaveData;
  };
  loading?: boolean;
  fetchUIDs?: (uids: any) => void;
}

export class TagUIDSComponent extends React.Component<TagUIDSProps> {

  public componentDidUpdate (prevProps) {
    console.log('prevProps', prevProps)
    if (prevProps.selected !== this.props.selected) {
      console.log("Change");
      // TODO: check if local exists
      const uids = this.props && this.props.tagUids && Object.keys(this.props.tagUids);
      if (uids) {
        this.props.fetchUIDs!(uids);
      }
    }
  }

  public goUID (e) {
    // e.stopPropagation();
    console.log('click');
    // const uids = this.props && this.props.tagUids && Object.keys(this.props.tagUids);
    // this.props.fetchUIDs!(uids);
  }

  public render () {
    const { tagUids, uids, loading } = this.props;

    return (
      <div className="tag-uids" onClick={(e) => this.goUID(e)}>
        {
          loading ?
           <CircularProgress /> :
            tagUids ? null : <div>No IDs</div>
        }
        {
          uids ?
            map(tagUids, (uidData, uid) =>
              uids[uid] ?
                <div key={uid} className="tag-uids__item">
                  <Card style={{ width: '21rem' }}>
                    <CardPrimaryAction>
                    {
                      checkIfYoutube(uids[uid].uri) ?
                        <CardMedia
                          sixteenByNine
                          style={
                           {
                              backgroundImage: `url(https://img.youtube.com/vi/${checkIfYoutube(uids[uid].uri)}/0.jpg)`
                            }
                          }
                        /> : null
                    }
                      <div style={{ padding: '0 1rem 1rem 1rem' }}>
                        <Typography use="headline6" tag="h2">
                          {uids[uid].title}
                        </Typography>
                        <Typography
                          use="subtitle2"
                          tag="h3"
                          theme="textSecondaryOnBackground"
                          style={{ marginTop: '-1rem' }}
                        >
                          {uids[uid].uri}
                        </Typography>
                        <Typography
                          use="body1"
                          tag="div"
                          theme="textSecondaryOnBackground"
                        >
                          {uids[uid].text}
                        </Typography>
                      </div>
                    </CardPrimaryAction>
                    <CardActions>
                      <CardActionButtons>
                        <a href={uids[uid].uri} target="_blank" rel="noopener noreferrer">
                          <CardActionButton>Open link</CardActionButton>
                        </a>
                      </CardActionButtons>
                      <CardActionIcons>
                        <CardActionIcon onIcon="favorite" icon="favorite_border" />
                        <CardActionIcon icon="share" />
                        <CardActionIcon icon="more_vert" />
                      </CardActionIcons>
                    </CardActions>
                  </Card>
                </div> :
                null
            )
            : 'No ID'
        }
      </div>
    );
  }
};


const mapStateToProps = (state: RootState) => ({
    ...state.uidState
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchUIDs: (uids: string[]) => dispatch(fetchUIDs(uids))
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagUIDSComponent);
