import * as React from "react";
import { withRouter } from "react-router-dom";
import { PasswordForgetLink } from "../PasswordForget";
import { SignUpLink } from "../SignUp";
import { SignInGoogle } from "./SignInGoogle";

const SignInComponent = ({ history }: { [key: string]: any }) => (
  <div>
    <h1>SignIn</h1>
    <SignInGoogle history={history} />
    <SignUpLink />
    <PasswordForgetLink />
  </div>
);

export const SignIn = withRouter(SignInComponent);
