import * as React from "react";
import Moment from "react-moment";

import { TabGroupI, TabsData } from "..";
import { privateTabsRef } from "src/util/database";
import { TabCards } from "./TabCards";
import './TabCards.scss';

import { Typography } from "@rmwc/typography";
import { ListDivider } from "@rmwc/list";
import { IconButton } from "@rmwc/icon-button";
import { TextField } from "@rmwc/textfield";

export const TabGroup = (props: { group: TabGroupI, groupId: string, tabs: TabsData, miniChecked: boolean, cardChecked: boolean; }) => {


  function deleteGroup (groupId) {
    privateTabsRef().child('groups').child(groupId).remove();
  }

  const { group, groupId, miniChecked, cardChecked, tabs } = props;

  const [ editGroupName, setEditGroupName ] = React.useState(false)
  const [ groupNameValue, setGroupNameValue ] = React.useState(group.name)

  const containsTabs = group.tabs && Object.keys(group.tabs).length > 0;

  function setGroupNameState (text: string) {
    setGroupNameValue(text)
  }

  function toggleEditGroupName (editOn: boolean) {
    setEditGroupName(editOn)
  }

  function fieldKeyDown (e) {
    if (e.key === "Enter") {
      updateGroupName(groupNameValue);
    }
  }

  function updateGroupName (text: string) {
    privateTabsRef().child('groups').child(groupId).child('name').set(text).then(() => {
      toggleEditGroupName(false)
    })
  }

  const [ groupMinimized, setMinimized ] = React.useState(true);


  return (
    <div className="tab-cards__container">

      <div className="tab-cards__header" onClick={() => setMinimized(!groupMinimized)}>
        {
          editGroupName ?
            <div className="group-title__edit">
              <TextField
                label="Set group name"
                className="group-title__edit-field"
                textarea={groupNameValue.length >= 90}
                rows={groupNameValue.length >= 90 ? 6 : 1}
                value={groupNameValue}
                onChange={(e) => setGroupNameState((e.target as any).value)}
                onKeyDown={(e) => fieldKeyDown(e)}
                onClick={(e) => e.stopPropagation()}
                autoFocus
              />
              {groupNameValue.length}
              <IconButton icon="backup" onClick={(e) => {
                e.stopPropagation();
                updateGroupName(groupNameValue);
              }} />
              <IconButton icon="close" onClick={(e) => {
                e.stopPropagation();
                toggleEditGroupName(false);
              }} />
            </div>
            :
            <div className="group-title" id={groupId}>
              <div className="group-title-text" onClick={(e) => {
                e.stopPropagation();
                console.log("sdclik")
                // Open group when editing, only when not open yet
                if (!editGroupName && groupMinimized) {
                  setMinimized(false)
                }
                toggleEditGroupName(true)
              }}>
                  <Typography use="headline6">
                    { `${groupNameValue} ${ containsTabs ? `(${Object.keys(group.tabs).length})` : '-Empty-' }` }
                  </Typography>

                  {
                    containsTabs ?
                      null
                      :
                      <IconButton
                        icon="close"
                        style={{ color: 'red' }}
                        onClick={() => deleteGroup(groupId)}
                      />
                  }
              </div>

              <small className="group-title-date">
                <Moment fromNow>{group.dateCreated}</Moment>
              </small>
            </div>
        }

        <IconButton className="tab-cards__header__expand" icon="expand_more" onClick={(e) => {
          setMinimized(!groupMinimized)
        }} />


      </div>

      {
        groupMinimized ?
          null
          :
          <TabCards group={group} groupId={groupId} tabs={tabs} miniChecked={miniChecked} cardChecked={cardChecked} minimized={groupMinimized} />
      }

      <ListDivider style={{ paddingBottom: '1rem' }} />

    </div>
  );
};


export {}
