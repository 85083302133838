export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const LANDING = "/";
export const HOME = "/home";
export const URIS = "/uris";
export const TAGS = "/tags";
export const SESSIONS = "/sessions";
export const GROUPS = "/groups";
export const ACCOUNT = "/account";
export const USERS = "/users";
export const PASSWORD_FORGET = "/pw-forget";
