import * as React from "react";
import { map, reduce } from 'lodash';
import { URISuffix } from "./URISuffix";
import { Typography } from "@rmwc/typography";
import { Icon } from "@rmwc/icon";
import { Ripple } from "@rmwc/ripple";


interface URIPathsProps {
  paths: {
    [key: string]: URIPathsProps | string;
  } | string | any;
  parent?: string;
  tld?: string;
  fbPath?: any;
  open?: boolean;
  uid?: string;
}


interface URIPathsState {
  childrenOpen: {
    [key: string]: boolean | any;
  };
}

export class URIPaths extends React.Component <URIPathsProps, URIPathsState> {

  public static defaultProps = {
    open: false
  }

  public static getDerivedStateFromProps (props, state) {
    return {
      childrenOpen: {
        // Set initial keys to 'open'
        ...reduce(props.paths, (memo, pathsItem, key: string) => {
          memo[key] = props.open;
          return memo;
        }, {}),
        // State later takes precedence
        ...state.childrenOpen
      }
    }
  }

  constructor(props: URIPathsProps) {
    super(props);
    this.state = {
      childrenOpen: {}
    };
  }

  public toggleChild (e: React.MouseEvent<any, MouseEvent>, key: string) {
    e.stopPropagation();
    this.setState({
      childrenOpen: {
        ...this.state.childrenOpen,
        [key]: !this.state.childrenOpen[key]
      }
    })
  }
  public negate (e: React.MouseEvent<any, MouseEvent>) {
    e.stopPropagation();
  }

  public isChildOpen (key: string) {
    return this.state.childrenOpen[key] ? this.state.childrenOpen[key] : false;
  }

  public render () {
    const { paths, parent, fbPath, open } = this.props;
    return (
      <div className="uri-paths" onClick={(e) => this.negate(e)}>
        {
          map(paths, (pathsItem, key) => {
            if (typeof pathsItem === 'object' && key !== '_') {

              // Turn into path
              const pathParent = parent ?
                `${parent}/${key}` :
                key;

              const path = fbPath ?
                `${fbPath}/${key}` :
                key;

              return (
                <div key={key} className="uri-paths-group">

                  { pathParent ?
                    <a
                      href={`https://${pathParent}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography use="headline5">{key}</Typography>
                    </a>
                    : <Typography use="headline5">{key}</Typography>
                  }

                  <Ripple accent>
                    <Icon
                      icon={{ icon: this.isChildOpen(key) ? 'chevron_left' : 'chevron_right', size: 'xlarge' }}
                      onClick={(e) => this.toggleChild(e, key) }
                     />
                   </Ripple>


                  {
                    this.isChildOpen(key) ?
                      <div>
                        <img src={`https://img.youtube.com/vi/${key}/0.jpg`} alt={key} />
                        <URIPaths paths={pathsItem} parent={pathParent} fbPath={path} open={open} />
                      </div>
                      :
                      null
                  }


                </div>
              )
            } else if (key === '_') {
              const path = `${fbPath}/_`;
              return <URIPath key={key} paths={pathsItem} parent={parent} fbPath={path} uid={parent}/>
            }
            else {
              console.log("ERROR2:", paths);
              return <div key={key}>ERROR</div>
            }
          })
        }
      </div>
    )
  }
}


export const URIPath = (props: URIPathsProps) => {
  return (
    <div style={{ paddingLeft: '10px' }}>
      <URISuffix pathData={props.paths} parent={props.parent} fbPath={props.fbPath} uid={props.uid} />
    </div>
  )
}
