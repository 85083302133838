import { ofType, Epic } from 'redux-observable';
import { map, flatMap, catchError, mergeMap } from 'rxjs/operators';
import { from, of, Observable, concat } from 'rxjs';

import { privateUIDRef } from 'src/util/database';
import { Action } from '.';
import { UIDS_FETCH, UID_FETCH, fetchUIDDone, FetchUIDSAction, FetchUID } from "src/reducers/uids";


export const fetchUIDsEpic: Epic = (action$: Action, store): Observable<Action> => action$.pipe(
  ofType(UIDS_FETCH),
  flatMap((act: FetchUIDSAction) =>
    act.payload.map((uid: string) =>
      ({ type: UID_FETCH, payload: uid })
    )
  )
);



export const fetchUIDEpic: Epic = (action$: Action, store): Observable<Action> => action$.pipe(
  ofType(UID_FETCH),
  mergeMap((act: FetchUID) =>
    concat(
      from(privateUIDRef().child(act.payload).once('value')).pipe(
        map(snap => snap.val()),
        mergeMap(snap =>
          of(fetchUIDDone(snap['_'], act.payload))
        ),
        catchError((err) =>
          of(fetchUIDDone(err, act.payload))
        )
      )
    )
  )
)
