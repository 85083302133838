import * as React from "react";
import { URIObject } from "src/constants/URIs";
import { Card } from "@rmwc/card";
import { Typography } from "@rmwc/typography";

import './URIBreakdown.scss';
import { Ripple } from "@rmwc/ripple";

export const URIBreakdown = (props: URIObject) => {
  return (
    <Card className="uri-breakdown">

      <Typography use="headline6">URI Breakdown</Typography>
      <Typography use="subtitle2"><a href={props.uri} target="_blank" rel="noopener noreferrer">{props.uri}</a></Typography>


      <Typography
        className="uri-breakdown-body"
        use="body1"
        tag="div"
        theme="textSecondaryOnBackground"
      >
        <div className="uri-breakdown-body__item">
          <div><b>TLD:</b> {props.tld}</div>
          <div><b>path:</b> {props.path}</div>
          <div><b>params:</b> {props.params}</div>
        </div>

        <div className="uri-breakdown-body__item">
          <b>paths:</b>
          <ol>
            {props.paths && props.paths.map((item, key) =>
              <li key={key}> {item} </li>
            )}
          </ol>
        </div>
      </Typography>

      <div>
        <Typography
            use="body1"
            tag="div"
            theme="textSecondaryOnBackground"
          >

          <div className="uri-breakdown-tld">

            <Ripple accent>
              <div className="uri-breakdown-tld__item">
                {props.tld}
              </div>
            </Ripple>

              {props.paths &&
                props.paths.map((item, key) =>
                  (<Ripple key={key} accent><div className="uri-breakdown-tld__item"> {item} </div></Ripple>)
              )}

            <Ripple accent>
              <div className="uri-breakdown-tld__item">
                {props.params}
              </div>
            </Ripple>

          </div>

        </Typography>
      </div>
    </Card>
  );
};
