import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware, combineEpics } from 'redux-observable';

import { rootReducer } from "../reducers";
import { fetchUIDsEpic, fetchUIDEpic } from "src/epics/uids";
import { watchUpdateURIEpic } from "src/epics/uris";


export const rootEpic = combineEpics(
  fetchUIDsEpic,
  fetchUIDEpic,
  watchUpdateURIEpic
);

export function configureStore() {

  const epicMiddleware = createEpicMiddleware();

  const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(epicMiddleware)
    )
  );

  epicMiddleware.run(rootEpic);

  return store;
}
